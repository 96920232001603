import { Component } from "react";
import { getFooterData } from "../../helper";

import "./footer.css";

export default class Footer extends Component {
  storeData = this.props.storeData;

  state = {
    footer: "",
  };

  componentDidMount() {
    this.setState({ footer: getFooterData(this.storeData) });
  }

  render() {
    const footer = this.state.footer;

    return (
      <div className="row m-0">
        <div className="col-12 p-0">
          <div
            className="footer"
            dangerouslySetInnerHTML={{ __html: footer }}
          ></div>
        </div>
      </div>
    );
  }
}
