import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import MaskGroup from "../../assets/Mask_Group_1.png";
import "./section.css";
import webpage from "../../assets/web-24px.svg";
import food from "../../assets/fastfood-24px.svg";
import easy from "../../assets/add_task-24px.svg";
import arrow from "../../assets/sync_alt-24px.svg";
import mail from "../../assets/mail_outline-24px.svg";
import addCart from "../../assets/add_shopping_cart-24px.svg";
import timeline from "../../assets/timeline-24px.svg";
import tracking from "../../assets/gps_fixed-24px.svg";
import feedback from "../../assets/star_half-24px.svg";
import factCheck from "../../assets/fact_check-24px.svg";
import { getAllLogo } from "../../api/service";

const KeyFeatures = () => {
  const [images, setImages] = React.useState([]);

  React.useEffect(() => {
    const func = async () => getImages();
    func();
  }, []);

  const getImages = async () => {
    await getAllLogo()
      .then((data) => {
        console.log("Data Saved LOGO: ", data);
        setImages(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 12,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  return (
    <div>
      <div
        className="key-features"
        style={{ background: `url(${MaskGroup})` }}
        id="key-features"
      >
        <div className="overlay">
          <div className="container text-center">
            <div className="heading">
              <h1 className="bold p-5">KeyFeatures</h1>
              <div className="row text-left d-flex justify-content-center">
                <div className="col-xs-12 col-sm-6 col-md-5 col-lg-3">
                  <div className="d-flex work-block">
                    <img alt="logo" src={webpage} className="icon-svg" />
                    <div>
                      <h5 className="bold mt-2">Dedicated Webpage</h5>
                      <p className="mt-3 ml-2">
                        In addition to Ordering Page, Eatachi allow restaurant
                        to setup dedicated restaurant landing page to provide
                        key insights into your restaurant concept, contact
                        information and customer feedbacks.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-5 col-lg-3">
                  <div className="d-flex work-block">
                    <img alt="logo" src={food} className="icon-svg" />
                    <div>
                      <h5 className="bold">Online Ordering Website</h5>
                      <p className="mt-3 ml-2">
                        Eatachi allow Restaurants to setup FREE Online Ordering
                        Website for their restaurant in minutes. Get your
                        restaurant’s own online ordering website setup in
                        minutes and start taking orders right away.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-5 col-lg-3">
                  <div className="d-flex work-block">
                    <img alt="logo" src={easy} className="icon-svg" />
                    <div>
                      <h5 className="bold mt-2">Easy Setup</h5>
                      <p className="mt-3 ml-2">
                        Eatachi offers easy to use templates & libraries that
                        allows restaurant to setup their own online ordering
                        website in minutes and start serving orders online.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-5 col-lg-3">
                  <div className="d-flex work-block">
                    <img alt="logo" src={arrow} className="icon-svg" />
                    <div>
                      <h5 className="bold">Integrated with Social Media</h5>
                      <p className="mt-3 ml-2">
                        Enhance your social media (Facebook, Google, Instagram,
                        Twitter) posts, with instant “Order Now” button on your
                        posts, to allow foodies to take immediate action and
                        order on your website.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-5 col-lg-3">
                  <div className="d-flex work-block">
                    <img alt="logo" src={mail} className="icon-svg" />
                    <div>
                      <h5 className="bold mt-2">SMS & Email Alerts</h5>
                      <p className="mt-3 ml-2">
                        You will not miss any order that comes to you online, as
                        Eatachi sends you SMS & Email Alerts on all New Orders.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-5 col-lg-3">
                  <div className="d-flex work-block">
                    <img alt="logo" src={addCart} className="icon-svg" />
                    <div>
                      <h5 className="bold mt-2">“Order Now” Widget</h5>
                      <p className="mt-3 ml-2">
                        if you already have a website for your restaurant, you
                        can add “Order Now” widget easily and start getting
                        orders online for FREE.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-5 col-lg-3">
                  <div className="d-flex work-block">
                    <img alt="logo" src={timeline} className="icon-svg" />
                    <div>
                      <h5 className="bold mt-2">Intelligent Insights</h5>
                      <p className="mt-3 ml-2">
                        With Eatachi, get the detail insights of your online
                        orders, customer base, feedbacks, ratings & preference.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-5 col-lg-3">
                  <div className="d-flex work-block">
                    <img alt="logo" src={tracking} className="icon-svg" />
                    <div>
                      <h5 className="bold mt-2">Order Tracking</h5>
                      <p className="mt-3 ml-2">
                        After ordering from your Restaurant’s Online Ordering
                        System, your Customers can track the order easily and
                        contact you in case of any query.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-5 col-lg-3">
                  <div className="d-flex work-block">
                    <img alt="logo" src={feedback} className="icon-svg" />
                    <div>
                      <h5 className="bold mt-2">Customer Feedback</h5>
                      <p className="mt-3 ml-2">
                        At end of every order, Eatachi get customer feedback on
                        the order that allow your restaurant to enhance your
                        quality of service.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-5 col-lg-3">
                  <div className="d-flex work-block">
                    <img alt="logo" src={factCheck} className="icon-svg" />
                    <div>
                      <h5 className="bold mt-2">Marketing Services</h5>
                      <p className="mt-3 ml-2">
                        Eatachi offers online marketing that allow restaurants
                        to generate higher number of orders by bringing more
                        customers to orders on your website.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="brand-logo-section container">
        <div className="text-center">
          <h1 className="p-3 orange bold">
            Some of the Restaurants that uses Eatachi
          </h1>
          <h5 className="p-1 blue normal">Online Ordering System</h5>
        </div>

        {images.length > 0 ? (
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={2000}
          >
            {images.map((data, i) =>
              !data.LogoURL ? null : (
                <a
                  key={"a_" + i}
                  href={data.EatachiUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt={data.Name}
                    key={`img-${i}`}
                    src={data.LogoURL}
                    className="img-fluid"
                  />
                </a>
              )
            )}
          </Carousel>
        ) : null}
      </div>
    </div>
  );
};

export default KeyFeatures;
