import { Component, createRef } from "react";
import { Redirect } from "react-router-dom";
import ReactGA from "react-ga";

import { getStoreInfo, weAreClosed } from "../helper";
import { loading } from "./loading/loading";

import Header from "./header/header";
import Footer from "./footer/footer";
import Cart from "./cart/cart";
import Menu from "./menu/menu.js";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PopUp from "../components/PromotionPopUp/PopUp";

import { formatPrice } from "../helper";
import Location from "./locationModal/location";

export default class OrderNow extends Component {
  constructor(props) {
    super(props);

    const searchString = props.location.search;
    const params = new URLSearchParams(searchString);

    this._modeParam = "";
    if (params.get("mode")) this._modeParam = params.get("mode").toUpperCase();

    this._cartRef = createRef();
    this._menuRef = createRef();
  }

  state = {
    isLoading: true,
    storeData: { StoreId: 0 },
  };

  callAddItemToCart = (item) => {
    this._cartRef.current.addToCartClicked(item);
  };

  checkItemDiscount = (check) => {
    this._cartRef.current.handleDiscountStore(check);
  };

  handleLocation = (fee) => {
    this._cartRef.current.handleSelectLocation(fee);
  };

  handleOrderTypeRef = (data) => {
    if (this._cartRef.current) this._cartRef.current.handleOrderType(data);
  };

  callGetCartItems = () => {
    return this._cartRef.current?.getCartItems();
  };

  refreshItemCountInMenu = () => {
    this._menuRef.current?.refreshMenu();
  };

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);

    getStoreInfo().then((response) => {
      this.setState({ storeData: response });
      this.setState({ isLoading: false });
    });
  }

  render() {
    const { isLoading, storeData } = this.state;

    if (!storeData.BrandName === false) {
      document.title = `${storeData.BrandName} - Online Order - Eatachi`;
    }

    if (isLoading) return loading();
    else if (storeData.StoreId === 0) return <Redirect to="/" />;
    else {
      return (
        <div className="container-fluid p-0">
          {storeData.IsClosed ? weAreClosed(storeData) : <div></div>}

          <Header id={storeData} />
          <div className="row amount_time no-gutters">
            <div className="col-sm-12 col-md-6 text-md-right text-sm-center text-xs-center m-auto ">
              <p>
                <MonetizationOnIcon /> Minimum Order:{" "}
                {this.state.storeData.Currency}{" "}
                {console.log("currency checking", this.state.storeData)}{" "}
                {formatPrice(this.state.storeData.MinimumOrder)}
              </p>
            </div>
            <div className="col-sm-12 col-md-6 text-md-left text-sm-center text-xs-center m-auto">
              <p>
                <WatchLaterIcon /> Delivery Time:{" "}
                {this.state.storeData.DeliveryMinutes} Minutes
              </p>
            </div>
          </div>

          <div className="row m-0">
            <Menu
              ref={this._menuRef}
              storeData={storeData}
              addEvent={this.callAddItemToCart}
              discountCheck={this.checkItemDiscount}
              cartItems={this.callGetCartItems}
            />

            {this._modeParam !== "D" && (
              <Location
                feeData={storeData.StoreDeliveryFees}
                handleLocation={this.handleLocation}
                handleOrderTypeRef={this.handleOrderTypeRef}
              />
            )}

            {<PopUp feeData={storeData.Offer[0]} />}

            <Cart
              ref={this._cartRef}
              storeData={storeData}
              modeParam={this._modeParam}
              refreshMenu={this.refreshItemCountInMenu}
            />
          </div>
          <Footer storeData={storeData} />
        </div>
      );
    }
  }
}
