import { callAPI } from "./apiCalls";

export const validateSubdomain = async (domainName) => {
  try {
    return await callAPI("store/byuri/" + domainName, "", null, "GET");
  } catch (ex) {
    console.log(ex);

    return { StoreId: 0 };
  }
};

export const getMenuItems = async (storeId) => {
  try {
    return await callAPI(
      "menuitem/bystoreforcart/" + storeId + "/-1/1",
      "",
      null,
      "GET"
    );
  } catch (ex) {
    console.log(ex);
    return [];
  }
};

export const placeOrder = async (storeOrderData) => {
  try {
    return await callAPI("order/placeorder/", "", storeOrderData, "POST");
  } catch (ex) {
    console.log(ex);
    return { OrderId: -1 };
  }
};

export const getOrderStatus = async (transNumber) => {
  try {
    return await callAPI(
      "order/statusbytransnumber/" + transNumber,
      "",
      null,
      "GET"
    );
  } catch (ex) {
    console.log(ex);
    return { OrderId: -1 };
  }
};

export const saveSignUpInfo = async (signupData) => {
  try {
    return await callAPI("RegisterUser", "", signupData, "POST");
  } catch (ex) {
    console.log(ex);
    return "Error";
  }
};
//
export const getAllLogo = async () => {
  try {
    // return await callAPI("Store/StoreNameAndURL", "", null, "GET");
    return await callAPI("Store/SliderDetails", "", null, "GET");
  } catch (ex) {
    console.log(ex);
    return [];
  }
};

export const getDeliveryFare = async (storeId, destination) => {
  try {
    return await callAPI(
      "store/DeliveryFare/" + storeId,
      "",
      destination,
      "POST"
    );
  } catch (ex) {
    console.log(ex);
    return { MinFare: -999, MaxFare: -999 };
  }
};
