import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Geocode from "react-geocode";

Geocode.setApiKey("AIzaSyA3FzKFHiA7bUcmOaubinG6wqCZt8Dw7Yk");
Geocode.enableDebug();

class LocationMap extends React.Component {
  state = {
    address: "",
    zoom: 15,
    height: 100,
    markerPosition: {
      lat: 0,
      lng: 0,
    },
    mapPosition: this.props.position,
  };

  componentDidUpdate(prevProps) {
    if (this.props.position !== prevProps.position) {
      this.setState({
        mapPosition: this.props.position,
      });
    }
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onInfoWindowClose = (event) => {};

  onMarkerDragEnd = (event) => {
    this.state = {
      address: "",
      city: "",
      area: "",
      state: "",
      zoom: 15,
      height: 400,
      mapPosition: {
        lat: 0,
        lng: 0,
      },
      markerPosition: {
        lat: 0,
        lng: 0,
      },
    };
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();

    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        this.setState({
          markerPosition: {
            lat: newLat,
            lng: newLng,
          },
          mapPosition: {
            lat: newLat,
            lng: newLng,
          },
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  onPlaceSelected = (place) => {
    console.log("plc", place);
    const address = place.formatted_address,
      addressArray = place.address_components,
      city = this.getCity(addressArray),
      area = this.getArea(addressArray),
      state = this.getState(addressArray),
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();

    console.log("latvalue", latValue);
    console.log("lngValue", lngValue);

    // Set these values in the state.
    this.setState({
      address: address ? address : "",
      area: area ? area : "",
      city: city ? city : "",
      state: state ? state : "",
      markerPosition: {
        lat: latValue,
        lng: lngValue,
      },
      mapPosition: {
        lat: latValue,
        lng: lngValue,
      },
    });
  };

  onClick = (event) => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();
    console.log("onclick", newLat, newLng);
    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        console.log("Responses", response);
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          state = this.getState(addressArray);
        this.setState({
          address: address ? address : "",
          area: area ? area : "",
          city: city ? city : "",
          state: state ? state : "",
          markerPosition: {
            lat: newLat,
            lng: newLng,
          },
          mapPosition: {
            lat: newLat,
            lng: newLng,
          },
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  render() {
    const containerStyle = {
      height: "200px",
    };

    return (
      <div style={{ paddingBottom: 15 }}>
        <LoadScript googleMapsApiKey="AIzaSyA3FzKFHiA7bUcmOaubinG6wqCZt8Dw7Yk">
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={this.state.mapPosition}
            zoom={15}
            options={{ disableDefaultUI: true }}
          >
            <Marker
              position={this.state.mapPosition}
              draggable={true}
              onDragEnd={this.onMarkerDragEnd}
            />
          </GoogleMap>
        </LoadScript>
      </div>
    );
  }
}

export default LocationMap;
