import React from "react";
import "./section.css";
import { Link } from "react-scroll";
import MoreByEatachi from "../../assets/Mask_Group_2.png";
import { Button } from "@material-ui/core";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import DevicesOutlinedIcon from "@material-ui/icons/DevicesOutlined";
import StarHalfIcon from "@material-ui/icons/StarHalf";
import MenuIcon from "@material-ui/icons/Menu";
import EventSeatIcon from "@material-ui/icons/EventSeat";
import TimelineIcon from "@material-ui/icons/Timeline";
import ScatterPlotIcon from "@material-ui/icons/ScatterPlot";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(60),
    },
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

const Testimonials = () => {
  const [testimonial, setTestimonial] = React.useState([]);

  React.useEffect(() => {
    //const funcCall = async () => await getData();
    // funcCall();

    getData();
  }, []);

  const getData = async () => {
    const api = "./data.json";

    fetch(api, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(async (response) => {
        if (response.ok) return Promise.resolve(response.json());

        return Promise.reject("error loading json file");
      })
      .then((data) => {
        setTestimonial(data);
      })
      .catch((err) => console.log(err));
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const classes = useStyles();

  return (
    <div id="Testimonials">
      <div className="row mt-5 no-gutters ">
        <div className="col-sm-12 col-md-6 testimonials-background">
          <div className="row text-left no-gutters">
            <div className="col-md-3 col-lg-6  "></div>
            <div className="col-sm-12 col-md-9 col-lg-6  mt-5 mb-5 heading-block-blue">
              <h3 className="white ml-5">Testimonials</h3>
            </div>
          </div>
          {testimonial && testimonial.length > 0 ? (
            <Carousel
              responsive={responsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              style={{ height: "180%" }}
            >
              {testimonial.map((data, i) => {
                return (
                  <div
                    key={"carousel-" + i}
                    className={` ${classes.root} d-flex justify-content-center text-center mt-5 mb-5`}
                  >
                    <Paper
                      elevation={3}
                      className="p-5 d-flex justify-content-center flex-column testimonials-block"
                    >
                      <img
                        src={data.imageSrc}
                        alt="logo"
                        className="img-fluid"
                      />
                      <p className="mt-2">{data.message}</p>
                      <div className="mt-3">
                        <p className="bold">{data.name}</p>
                        <p className="bold">{data.restaurantName}</p>
                      </div>
                    </Paper>
                  </div>
                );
              })}
            </Carousel>
          ) : null}
        </div>
        <div className="col-sm-12 col-md-6">
          <div
            className="More-By-Eatachi"
            style={{ background: `url(${MoreByEatachi})` }}
          >
            <div className="row text-right no-gutters">
              <div className="col-sm-12 col-md-9 col-lg-6 mt-5 mb-5 heading-block-white">
                <h3 className="blue mr-5">More By Eatachi</h3>
              </div>
            </div>
            <div className="row mt-5 d-flex justify-content-center">
              <div className="col-sm-10 col-md-10 col-lg-5">
                <div className="d-flex flex-row More-By-Eatachi-block">
                  <DevicesOutlinedIcon className="icon-background" />
                  <h5
                    className="bold align-middle"
                    style={{ display: "inline-block" }}
                  >
                    Online Ordering Website
                  </h5>
                </div>
              </div>
              <div className="col-sm-10 col-md-10 col-lg-5">
                <div className="d-flex flex-row More-By-Eatachi-block">
                  <StarHalfIcon className="icon-background" />
                  <h5
                    className="bold align-middle"
                    style={{ display: "inline-block" }}
                  >
                    Customer Feedback System
                  </h5>
                </div>
              </div>
              <div className="col-sm-10 col-md-10 col-lg-5">
                <div className="d-flex flex-row More-By-Eatachi-block">
                  <MenuIcon className="icon-background" />
                  <h5
                    className="bold align-middle"
                    style={{ display: "inline-block" }}
                  >
                    Digital Menu
                  </h5>
                </div>
              </div>
              <div className="col-sm-10 col-md-10 col-lg-5">
                <div className="d-flex flex-row More-By-Eatachi-block">
                  <EventSeatIcon className="icon-background" />
                  <h5
                    className="bold align-middle"
                    style={{ display: "inline-block" }}
                  >
                    Online Reservations
                  </h5>
                </div>
              </div>
              <div className="col-sm-10 col-md-10 col-lg-5">
                <div className="d-flex flex-row More-By-Eatachi-block">
                  <TimelineIcon className="icon-background" />
                  <h5
                    className="bold align-middle"
                    style={{ display: "inline-block" }}
                  >
                    Point of Sale
                  </h5>
                </div>
              </div>
              <div className="col-sm-10 col-md-10 col-lg-5">
                <div className="d-flex flex-row More-By-Eatachi-block">
                  <ScatterPlotIcon className="icon-background" />
                  <h5
                    className="bold align-middle"
                    style={{ display: "inline-block" }}
                  >
                    And Much More...
                  </h5>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-5 p-4">
              <Link
                to="fullName"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                <Button
                  offset="100"
                  variant="contained"
                  color="primary"
                  className="OrderNow"
                >
                  Contact Us
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
