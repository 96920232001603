import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import CloseIcon from "@material-ui/icons/Close";

export default function PopUp({ feeData }) {
  const [open, setOpen] = React.useState(false);
  const styleSheet = {
    iconDiv: {
      position: "absolute",
      top: 5,
      right: 5,
    },
    iconStyle: { color: "black", backgroundColor: "white" },
    imageStyle: { width: "100%", height: "100%" },
    dialogBox: {
      width: 300,
      height: 400,
      padding: 0,
      overflow: "hidden",
    },
  };

  React.useEffect(() => {
    setTimeout(setOpen(true), 1000);
  }, []);

  return (
    <>
      {feeData?.IsPromotion === true ? (
        <div>
          <Dialog open={open} aria-labelledby="form-dialog-title">
            <div style={styleSheet.iconDiv}>
              <CloseIcon
                style={styleSheet.iconStyle}
                onClick={() => {
                  setOpen(false);
                }}
              />
            </div>
            <DialogContent style={styleSheet.dialogBox}>
              <div>
                <img
                  src={feeData.PromotionImageUrl}
                  alt="promotionImage"
                  style={styleSheet.imageStyle}
                />
              </div>
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
