import React from "react";

import MaskGroup from "../../assets/Mask_Group.png";

import "./section.css";

const section2 = () => {
  return (
    <div className="d-flex mt-3" id="about">
      <div className="text-center">
        <div className="container p-3">
          <h5 className="orange bold">
            Simple Digitalization Platform for Restuarants
          </h5>
          <p>
            Double your restaurant sales by offering Online Ordering facility to
            your customers. Digitize your restaurant business by having
            capabilities of receiving online orders from customers by empowering
            your restaurant with our FREE Restaurant Online Ordering System. Get
            your Restaurant’s Own Online Ordering System setup instantly (no
            trainings needed).
          </p>
        </div>
        <div className="maskGroup" style={{ background: `url(${MaskGroup})` }}>
          <div className="overlay">
            <div className="container p-5">
              <h3 className="bold">Some Insights of Eatachi</h3>
              <h3 className="bold">Online Ordering System For Restaurants</h3>
              <div className="row maskGroupRow no-gutters d-flex justify-content-between">
                <div className="col-xs-12 col-sm-6 col-md-2">
                  <h3>100+</h3>
                  <p>Restuarants</p>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-2">
                  <h3>2000+</h3>
                  <p>Orders Every Day</p>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-2">
                  <h3>5000+</h3>
                  <p>Meals Available</p>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-2">
                  <h3>$100K+</h3>
                  <p>Transactions Per Day</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default section2;
