import { Component } from "react";
import { getHeaderData } from "../../helper";
import { getHeaderMobileData } from "../../helper";

import "./header.css";

export default class Header extends Component {
  storeId = this.props.id;

  state = {
    header: "",
    mobileHeader: "",
    isMobileScreen: false,
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    // call so that flag is set per the current window size
    this.handleResize();

    this.setState({
      header: getHeaderData(this.storeId),
      mobileHeader: getHeaderMobileData(this.storeId),
    });
  }
  handleResize = () => {
    this.setState({ isMobileScreen: window.innerWidth < 768 });
  };

  render() {
    const { header, mobileHeader, isMobileScreen } = this.state;

    return (
      <div className="row m-0">
        <div className="col-12 p-0">
          <div
            dangerouslySetInnerHTML={{
              __html: isMobileScreen ? mobileHeader : header,
            }}
          ></div>
        </div>
      </div>
    );
  }
}
