import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Button } from "@material-ui/core";
import "./section.css";
import { scroller } from "react-scroll";

import logo from "../../assets/eatachi-logo.png";
const NavbarSection = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const updateWidth = () => {
    setWidth(window.innerWidth);
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });
  return (
    <Navbar
      collapseOnSelect
      sticky="top"
      bg="light"
      expand="lg"
      className="navbar-heigth"
    >
      <Container>
        <Navbar.Brand href="#home">
          <img src={logo} className="img-fluid" alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto text-center align-self-center">
            <Nav.Link
              onClick={() =>
                scroller.scrollTo("home", {
                  smooth: true,
                  offset: width > 768 ? -100 : -600,
                  duration: 500,
                })
              }
              href="#home"
            >
              Home
            </Nav.Link>
            <Nav.Link
              onClick={() =>
                scroller.scrollTo("about", {
                  smooth: true,
                  offset: width > 768 ? -100 : -600,
                  duration: 500,
                })
              }
              href="#about"
            >
              About Eatachi
            </Nav.Link>
            <Nav.Link
              onClick={() =>
                scroller.scrollTo("we-offer", {
                  smooth: true,
                  offset: width > 768 ? -100 : -600,
                  duration: 500,
                })
              }
              href="#we-offer"
            >
              What We Offers
            </Nav.Link>
            <Nav.Link
              onClick={() =>
                scroller.scrollTo("why-eatachi", {
                  smooth: true,
                  offset: width > 768 ? -100 : -600,
                  duration: 500,
                })
              }
              href="#why-eatachi"
            >
              Why Eatachi
            </Nav.Link>
            <Nav.Link
              href="#how-work"
              onClick={() =>
                scroller.scrollTo("how-work", {
                  smooth: true,
                  offset: width > 768 ? -100 : -600,
                  duration: 500,
                })
              }
            >
              How It Works
            </Nav.Link>
            <Nav.Link
              href="#key-features"
              onClick={() =>
                scroller.scrollTo("key-features", {
                  smooth: true,
                  offset: width > 768 ? -100 : -600,
                  duration: 500,
                })
              }
            >
              Key Features
            </Nav.Link>
            <Nav.Link
              href="#Testimonials"
              onClick={() =>
                scroller.scrollTo("Testimonials", {
                  smooth: true,
                  offset: width > 768 ? -100 : -600,
                  duration: 500,
                })
              }
            >
              Testimonials
            </Nav.Link>
            <Nav.Link
              href="https://storeportal.eatachi.co/Register"
              target="_blank"
            >
              <Button variant="contained" color="primary" className="OrderNow">
                SIGNUP NOW
              </Button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarSection;
