import { Component } from "react";
import { getStoreInfo } from "../helper";
import { loading } from "./loading/loading";
import Welcome from "./welcome";
import Navbar from "./sections/navbar";
import Home from "./sections/homeSection";
import Section from "./sections/section2";
import EatachiOffer from "./sections/EatachiOffer";
import WhyEatachi from "./sections/WhyEatachi";
import KeyFeatures from "./sections/KeyFeatures";
import Testimonials from "./sections/Testimonials";
import Footer from "./sections/FooterSection";

export default class defaultPage extends Component {
  state = { isLoading: true, storeData: { StoreId: 0 } };

  componentDidMount() {
    getStoreInfo().then((response) => {
      this.setState({ storeData: response });
      this.setState({ isLoading: false });
    });
  }

  render() {
    if (this.state.isLoading) return loading();
    else if (this.state.storeData.StoreId > 0)
      return <Welcome storeData={this.state.storeData} />;
    else
      return (
        <>
          <Navbar />
          <Home />
          <Section />
          <EatachiOffer />
          <WhyEatachi />
          <KeyFeatures />
          <Testimonials />
          <Footer />
        </>
      );
  }
}
