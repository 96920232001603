import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import "./location.css";

export default function FormDialog({
  feeData,
  handleLocation,
  handleOrderTypeRef,
}) {
  const [open, setOpen] = React.useState(false);
  const [location, setLocation] = React.useState("{}");
  const [orderType, setOrderType] = React.useState(0);
  const [isOrderTypeError, setIsOrderTypeError] = React.useState(false);
  const [isDeliveryFeesError, setIsDeliveryFeesError] = React.useState(false);

  React.useEffect(() => {
    if (feeData.length > 0) {
      const deliverfeeJson = localStorage.getItem("locationName") || "{}";
      const OrderTypeJSON = localStorage.getItem("orderType") || 0;
      setOrderType(Number(OrderTypeJSON));
      let deliveryObject = [];

      try {
        deliveryObject = JSON.parse(deliverfeeJson);
      } catch (ex) {
        console.log(ex);
      }

      if (deliveryObject) {
        setLocation(deliveryObject);
      }

      handleClickOpen();
    }
  }, []);

  const handleOrderTypeState = (event, newLocation) => {
    if (newLocation != null) {
      localStorage.setItem("orderType", newLocation);
      setOrderType(newLocation);
      handleOrderTypeRef(newLocation);
      setIsOrderTypeError(false);
    }
  };

  const SelectLocation = (fee) => {
    handleLocation(fee);
    setLocation(fee);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();

    if (orderType === null || isNaN(orderType)) setIsOrderTypeError(true);
    else {
      setIsOrderTypeError(false);

      // if delivery order then also check location
      if (orderType === 0) {
        try {
          if (Object.keys(location).length !== 0) handleClose();
          else setIsDeliveryFeesError(true);
        } catch (ex) {
          console.log("Location parsing exception:", ex);
          setIsDeliveryFeesError(true);
        }
      } else {
        setIsDeliveryFeesError(false);
        handleClose();
      }
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={handleSubmit}>
          <DialogContent className="dialogwidth">
            <DialogContentText>Select Order Type</DialogContentText>
            <Grid container justify="center" className="toggle-button-location">
              <ToggleButtonGroup
                value={orderType}
                exclusive
                onChange={handleOrderTypeState}
                aria-label="text alignment"
                className="w-100"
              >
                <ToggleButton
                  value={0}
                  style={{ width: "33%" }}
                  aria-label="left aligned"
                >
                  <p>Delivery</p>
                </ToggleButton>
                <ToggleButton
                  value={1}
                  style={{ width: "33%" }}
                  aria-label="right aligned"
                >
                  <p>Pick up</p>
                </ToggleButton>
                <ToggleButton
                  value={2}
                  style={{ width: "34%" }}
                  aria-label="right aligned"
                >
                  <p>Drive-Thru</p>
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            {isOrderTypeError ? (
              <p style={{ color: "red", fontSize: 11 }}>
                Order Type Must be Selected
              </p>
            ) : null}
            {orderType === 0 && (
              <>
                <DialogContentText style={{ marginTop: 20 }}>
                  Select your area for delivery
                </DialogContentText>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="q-select-label">Area</InputLabel>
                  <Select
                    name="deliveryCharge"
                    labelId="area-select-label"
                    label="Area"
                    required
                    value={
                      location
                        ? "fee-" +
                          location.DeliveryFeesId +
                          "-" +
                          location.DeliveryFees
                        : ""
                    }
                  >
                    {feeData.length > 0
                      ? feeData.map((fee) => (
                          <MenuItem
                            key={"fee-" + fee.DeliveryFeesId}
                            value={
                              "fee-" +
                              fee.DeliveryFeesId +
                              "-" +
                              fee.DeliveryFees
                            } // this is done to make unique values as fees could be same for multiple areas.
                            onClick={() => SelectLocation(fee)}
                          >
                            {fee.LocationName}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
                {isDeliveryFeesError ? (
                  <p style={{ color: "red", fontSize: 11 }}>
                    Delivery location Must be Selected
                  </p>
                ) : null}
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="OrderNow"
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
