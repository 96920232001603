import React from "react";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import DevicesIcon from "@material-ui/icons/Devices";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import SettingsIcon from "@material-ui/icons/Settings";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import whyEatachi from "../../assets/why_eatachi.png";
import edit from "../../assets/edit_off-24px.svg"
import SupportAgent from '../../assets/support_agent-white.svg'

const WhyEatachi = () => {
  return (
    <div>
      <div className="why-section" style={{ background: `url(${whyEatachi})` }} id="why-eatachi">
        <div className="overlay">
          <div className="container text-center">
            <div className="heading">

              <h3 className="bold">Why Eatachi?</h3>
            </div>
            <div className="row ">
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 white">
                <div className="d-flex flex-row why-block">
                  <CheckCircleOutlineIcon className="icon-background" />
                  <h5
                    className="bold align-middle"
                    style={{ display: "inline-block" }}
                  >
                    It's Easy
                  </h5>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 white">
                <div className="d-flex flex-row why-block">
                  <DevicesIcon className="icon-background" />
                  <h5
                    className="bold align-middle"
                    style={{ display: "inline-block" }}
                  >
                    Ready to Use
                  </h5>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 white">
                <div className="d-flex flex-row why-block">
                  <EmojiObjectsIcon className="icon-background" />
                  <h5
                    className="bold align-middle"
                    style={{ display: "inline-block" }}
                  >
                    Free to Start
                  </h5>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 white">
                <div className="d-flex flex-row why-block">
                  <SettingsIcon className="icon-background" />
                  <h5
                    className="bold align-middle"
                    style={{ display: "inline-block" }}
                  >
                    No Setup Cost
                  </h5>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 white">
                <div className="d-flex flex-row why-block">
                  <MonetizationOnIcon className="icon-background" />
                  <h5
                    className="bold align-middle"
                    style={{ display: "inline-block" }}
                  >
                    No Monthly Charges
                  </h5>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 white">
                <div className="d-flex flex-row why-block">
                  <MoneyOffIcon className="icon-background" />
                  <h5
                    className="bold align-middle"
                    style={{ display: "inline-block" }}
                  >
                    No Commission
                  </h5>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 white">
                <div className="d-flex flex-row why-block">
                <img src={edit} className="icon-background img-fluid" alt="icon" style={{height: '50px'}}/>
                  <h5
                    className="bold align-middle"
                    style={{ display: "inline-block" }}
                  >
                    No Training Needed
                  </h5>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 white">
                <div className="d-flex flex-row why-block">
                <img src={SupportAgent} className="icon-background img-fluid" alt="icon"/>
                  
                  <h5
                    className="bold align-middle"
                    style={{ display: "inline-block" }}
                  >
                    24/7 Support
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="work-section" id="how-work">
        <div className="container text-center">
          <div className="heading">
            <h3 className="orange bold">HOW IT WORKS?</h3>
            <h5 className="blue normal">It's easy as 1, 2, 3, 4</h5>
          </div>
          <div className="row text-left">
            <div className="col-xs-12 col-sm-6 col-md-3">
              <div className="d-flex work-block">
                <h1 className="orange bold">1</h1>
                <h5 className="align-baseline">Create Your Eatachi Account</h5>
              </div>
              <p style={{ marginLeft: "3rem"}}>
                Create your FREE Eatachi Account for your restaurant, choose
                your preferred website address, input your restaurant key
                information.
              </p>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3">
              <div className="d-flex work-block">
                <h1 className="orange bold">2</h1>
                <h5 className="align-baseline">Setup Your Restaurant Menu</h5>
              </div>
              <p style={{ marginLeft: "3rem" }}>
                Setup your restaurant menu by using our templates & libraries.
                No Trainings Needed, No Content or Pictures Needed.
              </p>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3">
              <div className="d-flex work-block">
                <h1 className="orange bold">3</h1>
                <h5 className="align-baseline">
                  Share Your Ordering Website Link
                </h5>
              </div>
              <p style={{ marginLeft: "3rem" }}>
                Add “Order Now” button on your Facebook & Instagram page and
                start sending your dedicated Online Ordering Website Link to
                your customers to get online orders.
              </p>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3">
              <div className="d-flex work-block">
                <h1 className="orange bold">4</h1>
                <h5 className="align-baseline" >Start Getting Orders</h5>
              </div>
              <p style={{ marginLeft: "3rem" }}>
                Once customer orders, you get the alert on SMS & Email, just
                click the link to start serving orders.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyEatachi;
