import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { loadReCaptcha } from "react-recaptcha-v3";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet";

import defaultPage from "./components/default";
import OrderNow from "./components/ordernow";
import OrderStatus from "./components/orderstatus/orderstatus";
import Privacy from "./components/privacy";

import { getStoreInfo } from "./helper";

import "./App.css";

function App() {
  const [storeInfo, setStoreInfo] = useState({ StoreId: 0 });

  useEffect(() => {
    getStoreInfo().then((storeData) => setStoreInfo(storeData));
  }, []);

  ReactGA.initialize("UA-189761338-1");
  loadReCaptcha("6LcsMHkaAAAAAF2jTggA8xDutHZmTPzwnL1mIzJw");

  ReactPixel.init("3252515874851923", {}, { debug: true, autoConfig: false });
  ReactPixel.pageView();
  ReactPixel.fbq("track", "PageView");

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/orderstatus" component={OrderStatus} />
          <Route path="/ordernow" component={OrderNow} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/" component={defaultPage} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
