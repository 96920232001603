import React, { Component } from "react";
import { Button } from "@material-ui/core";

import { getMiddleData } from "../helper";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import Header from "./header/header";
import Footer from "./footer/footer";

export default class Welcome extends Component {
  storeData = this.props.storeData;

  constructor(props) {
    super(props);

    this.state = {
      middle: "",
    };
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);

    this.setState({ middle: getMiddleData(this.storeData) });
  }

  render() {
    const { middle } = this.state;
    if (!this.storeData.BrandName === false) {
      document.title = `${this.storeData.BrandName} - Eatachi`;
    }

    return (
      <div className="container-fluid p-0">
        <Header id={this.storeData} />

        <div className="row m-0 ">
          <div className="col-12 midImg ">
            <div className="m-5 d-flex flex-column align-items-center justify-content-center ">
              <div dangerouslySetInnerHTML={{ __html: middle }}></div>
              <div className="p-2">
                <Link to="/ordernow">
                  <Button
                    variant="contained"
                    color="primary"
                    className="OrderNow"
                  >
                    Order Now
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <Footer storeData={this.storeData} />
      </div>
    );
  }
}
