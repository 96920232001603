import Card from "react-bootstrap/Card";

import { LazyBackground } from "./components/lazyLoading/lazyLoading";

import { Button } from "@material-ui/core";

import { validateSubdomain } from "../src/api/service";

import headerImage from "./assets/Header.png";
import headerImageMobile from "./assets/header_mobile.png";
import powered from "./assets/Final_Abstract_Logo_Eeatachi_B.png";
import facebook from "./assets/SM-FB.png";
import linkedin from "./assets/SM-LI.png";
import Instagram from "./assets/SM-IG.png";
import twitter from "./assets/twitter_circle-512.png";

import AddIcon from "@material-ui/icons/Add";

export const formatPrice = (x, currency) => {
  switch (currency) {
    case "BRL":
      return x.toFixed(2).replace(".", ",");
    default:
      return new Intl.NumberFormat().format(x); //x.toFixed(2);
  }
};

export const emptyIfNull = (data) => {
  return data == null ? "" : data;
};

export const getStoreInfo = async () => {
  let subdomain = getSubdomain();

  if (subdomain === "") return { storeId: 0 };

  console.log("Subdomain: " + subdomain);

  let storeData = await validateSubdomain(subdomain);

  return storeData;
};

const getSubdomain = () => {
  let hostname = window.location.hostname.toLowerCase();

  hostname = hostname.replace("localhost", "");

  const hostnameSections = hostname.split(".");

  const first = hostnameSections[0];

  return first;
};

export const getHeaderData = (store) => {
  const storeBanner =
    store.BrandBanner === "" ? headerImage : store.BrandBanner;

  return `<div class="jumbotron-fluid">
  <div class="store-header d-flex justify-content-center align-items-center" style="background-image: 
        url(${storeBanner});">
       ${
         store.BrandLogo && store.BrandLogo !== ""
           ? `<img src="${store.BrandLogo}" class="img-fluid store-header-logo"/>`
           : ""
       }
       </div>       
      </div>
        `;
};

export const getHeaderMobileData = (store) => {
  const mobileBanner =
    store.MobileBanner === "" ? headerImageMobile : store.MobileBanner;

  return `<div class="jumbotron-fluid">
  <div class="store-header d-flex justify-content-center align-items-center" style="background-image: 
        url(${mobileBanner});">
      
       ${
         store.BrandLogo
           ? `<img src="${store.BrandLogo}" class="img-fluid store-header-logo"/>`
           : ""
       }
       </div>
      </div>
        `;
};

export const getMiddleData = (storeData) => {
  return `<div class="text-center">
  <h2>Welcome to </h2>
  <h1>${storeData.BrandName}</h1>
  </div>`;
};

export const getFooterData = (storeData) => {
  return `
      <div class="row m-0">
        <div class="d-none d-md-block col-md-2 order">
          <img class="img-fluid" src="${powered}"/>
        </div>
        <div class="col-md-3 col-xs-12 col-sm-6">
          <ul>
            <li class="title">Operating Hours</li>
            <li> ${emptyIfNull(storeData.OperatingHoursText)}</li>
          </ul>
        </div>
        <div class="col-md-2 col-xs-12 col-sm-6 ">
          <ul>
            <li class="title">Follow Us</li>

            ${
              storeData.FacebookId
                ? `<li class="icon">
              <a
                href="${storeData.FacebookId}"
                target="_blank"
                ><img src="${facebook}" class="img-fluid"/></a
              >
            </li>`
                : ``
            }

            ${
              storeData.TwitterId
                ? `<li class="icon">
              <a
                href="${storeData.TwitterId}"
                target="_blank"
                ><img src="${twitter}" class="img-fluid"/></a
              >
            </li>`
                : ``
            }
            ${
              storeData.InstagramId
                ? `<li class="icon">
              <a
                href="${storeData.InstagramId}"
                target="_blank"
                ><img src="${Instagram}" class="img-fluid"/></a
              >
            </li>`
                : ``
            }
            ${
              storeData.LinkedInId
                ? ` <li class="icon"> 
              <a
                href="${storeData.LinkedInId}"
                target="_blank"
                ><img src="${linkedin}" class="img-fluid"/></a
              >
            </li>`
                : ``
            }
          </ul>
        </div>
        <div class="col-md-2 col-xs-12 col-sm-6 ">
          <ul>
            <li class="title">Contact Us</li>
            <li>${emptyIfNull(storeData.OrderingPhoneNumber)}</li>
            <li>
              <a
                href="mailto:' +
    ${storeData.Email} +
    '"
                target="_self"
                >${storeData.Email}</a
              >
            </li>
            
          </ul>
        </div>
        <div class="col-md-3 col-xs-12 col-sm-6 ">
          <ul>
            <li class="title">Location</li>
            <li>
              ${storeData.StreetAddress}, ${storeData.City}, ${
    storeData.Country
  }
            </li>
          </ul>
        </div>
        <div class="col-12 text-right copyright " >
          <p>© 2020 All rights reserved © ${storeData.BrandName}</p>
        </div>
        <div class="d-sm-block d-md-none col-xs-12 col-sm-12 order-mobile text-center font-weight-bold">
          Powered by eatachi.co
        </div>
      </div>
      </div>
  `;
};
export const weAreClosed = (storeData) => {
  return (
    <div className="we-are-closed">
      We're closed for business right now.
      <br />
      {storeData.StoreClosingReason && storeData.StoreClosingReason.length > 0
        ? storeData.StoreClosingReason
        : "Our operating hours are: " + storeData.OperatingHoursText}
    </div>
  );
};

export const getMenuCard = (
  item,
  addEvent,
  Offer,
  discountCheck,
  itemOrderCount
) => {
  let newPrice = 0;
  let OfferValid = false;

  // NOTE: Item will have category level discount already applied.

  if (item.OfferAmount > 0) {
    discountCheck(item);
    OfferValid = true;
    newPrice = item.Price - item.OfferAmount;
  } else if (item.OfferPercent > 0) {
    discountCheck(item);
    OfferValid = true;
    newPrice = Math.round(item.Price - (item.Price / 100) * item.OfferPercent);
  } else if (Offer.length > 0 && Offer[0].Amount) {
    OfferValid = true;
    newPrice = item.Price - Offer[0].Amount;
  } else if (Offer.length > 0 && Offer[0].Percentage) {
    OfferValid = true;
    newPrice = Math.round(
      item.Price - (item.Price / 100) * Offer[0].Percentage
    );
  }

  let itemImage = "assets/no-image.jpg";

  if (item.ImagePath && item.ImagePath !== "") itemImage = item.ImagePath;

  return (
    <div key={item.MenuItemId} className="col-sm-12 col-md-6 col-lg-4">
      <Card key={item.MenuItemId} className="my-2">
        {itemOrderCount < 1 ? (
          ""
        ) : (
          <Card.Text
            style={{
              fontSize: 16,
              position: "absolute",
              right: 20,
              top: 20,
              color: "white",
              backgroundColor: "red",
              height: 30,
              width: 30,
              textAlign: "center",
              borderRadius: "50%",
              paddingTop: 2,
            }}
          >
            {itemOrderCount}
          </Card.Text>
        )}

        <LazyBackground src={itemImage} className="card-img-top" height={200} />
        <Card.Body>
          <Card.Title>{item.Name}</Card.Title>
          <Card.Text>{item.Description}</Card.Text>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-sm-between align-items-center">
          <span style={{ float: "left" }}>
            {OfferValid ? (
              <>
                <b className="menu-price">
                  {item.Currency} {formatPrice(newPrice)}
                </b>
                <br />
                <b className="menu-old-price">
                  {" "}
                  {item.Currency} {formatPrice(item.Price)}
                </b>
              </>
            ) : (
              <b className="menu-price">
                {item.Currency} {formatPrice(item.Price)}
              </b>
            )}
          </span>

          {item.IsOutOfStock ? (
            <span className="out-of-stock">Out of Stock</span>
          ) : (
            <Button
              onClick={() => addEvent(item)}
              variant="contained"
              color="primary"
              style={{ float: "right" }}
              className="OrderNow"
            >
              Add to Cart
            </Button>
          )}
        </Card.Footer>
      </Card>
    </div>
  );
};

//////////// NEW MENU DRAW //////////
export const getMenuCardTwo = (
  item,
  addEvent,
  Offer,
  discountCheck,
  itemOrderCount
) => {
  let newPrice = 0;
  let OfferValid = false;

  // NOTE: Item will have category level discount already applied.

  if (item.OfferAmount > 0) {
    discountCheck(item);
    OfferValid = true;
    newPrice = item.Price - item.OfferAmount;
  } else if (item.OfferPercent > 0) {
    discountCheck(item);
    OfferValid = true;
    newPrice = Math.round(item.Price - (item.Price / 100) * item.OfferPercent);
  } else if (Offer.length > 0 && Offer[0].Amount) {
    OfferValid = true;
    newPrice = item.Price - Offer[0].Amount;
  } else if (Offer.length > 0 && Offer[0].Percentage) {
    OfferValid = true;
    newPrice = Math.round(
      item.Price - (item.Price / 100) * Offer[0].Percentage
    );
  }

  let itemImage = "assets/no-image.jpg";

  if (item.ImagePath && item.ImagePath !== "") itemImage = item.ImagePath;

  return (
    <div key={item.MenuItemId} className="col-lg-4 col-sm-6 ">
      <Card
        key={item.MenuItemId}
        className="my-2"
        style={{ backgroundColor: "#f5f5f5", padding: 5, minHeight: 100 }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "70%" }}>
            <Card.Title style={{ marginBottom: 2, fontSize: 16 }}>
              {item.Name}
            </Card.Title>

            <Card.Text
              style={{
                marginBottom: 2,
                fontSize: 14,
                color: "#96a3c0",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                textOverflow: "ellipsis",
                overflow: "hidden",
                lineHeight: 1.57,
              }}
            >
              {item.Description}
            </Card.Text>

            <span style={{ float: "left", fontSize: 14 }}>
              {OfferValid ? (
                <>
                  <b className="menu-price">
                    {item.Currency} {formatPrice(newPrice)}
                  </b>
                  <br />
                  <b className="menu-old-price">
                    {" "}
                    {item.Currency} {formatPrice(item.Price)}
                  </b>
                </>
              ) : (
                <b className="menu-price">
                  {item.Currency} {formatPrice(item.Price)}
                </b>
              )}
            </span>
          </div>

          <div style={{ width: "30%", position: "relative" }}>
            {item.IsOutOfStock ? (
              <span
                className="out-of-stock"
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: 5,
                  fontSize: 11,
                  textAlign: "center",
                }}
              >
                Out of Stock
              </span>
            ) : (
              <div
                style={{
                  right: 0,
                  bottom: 0,
                  padding: "3px",
                  position: "absolute",
                  lineHeight: 0,
                  borderRadius: "5px 0px",
                  backgroundColor: "rgb(240, 90, 40)",
                  boxSizing: "border-box",
                  margin: "0px",
                  flexDirection: "row",
                  height: 32.14,
                  width: 32.14,
                  cursor: "pointer",
                }}
                onClick={() => addEvent(item)}
              >
                <AddIcon
                  style={{
                    color: "white",
                    fontSize: 25,
                  }}
                />
              </div>
            )}
            <img
              src={itemImage}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                borderRadius: "5px",
              }}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

export const updateLocalStorage = (cartItems) => {
  const cartItemsJSON = JSON.stringify(cartItems);

  // we have to make sure that undefined is not saved in storage
  if (cartItemsJSON) localStorage.setItem("cartItems", cartItemsJSON);
};

export const clearLocalStorage = () => {
  localStorage.removeItem("cartItems");
};
