import { Component } from "react";
import { Modal } from "react-bootstrap";
import {
  Button,
  IconButton,
  RadioGroup,
  Radio,
  FormControlLabel,
  MenuItem,
  Grid,
  TextField,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrosshairs, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import Badge from "@material-ui/core/Badge";
import Geocode from "react-geocode";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";

import { loading } from "../loading/loading";
import {
  formatPrice,
  updateLocalStorage,
  clearLocalStorage,
} from "../../helper";
import { placeOrder, getDeliveryFare } from "../../api/service";
import ForcedQuestionPopup from "./forcedQuestion";
import Answer from "./answer";

import "./cart.css";
import "../delivery/delivery.css";
import cartImg from "../../assets/cart.png";
import LocationMap from "../map/locationMap";

export default class Cart extends Component {
  cartCurrency = this.props.storeData.Currency;

  cartTotal = 0;

  successStoreOrder = {};

  state = {
    isLoading: true,
    hideCartColumn: false,
    showPopup: false,
    showQuestionPopup: false,
    showAddress: false,
    toggle: false,
    isPlacingOrder: false,
    isFinalizeOrder: false,
    showOrderSuccess: false,
    cartItems: [],
    answers: [],
    storeData: this.props.storeData,
    itemToAdd: {},
    formData: {
      name: "",
      phone: "",
      email: "",
      address: "",
      deliveryCharge: 0,
      instructions: "",
      LocationName: "",
    },

    itemDiscount: 0,
    discount: 0,
    delivery: -1,
    taxAmount: 0,
    subTotal: 0,
    cartTotal: 0,

    checkItemDiscount: [],
    deliveryFeesObject: [],
    orderType: 0,
    mapPosition: { lat: 0, lng: 0 },
    deliveryTime: "now",
    isFutureOrderAllowed: false,
    maxDaysForFutureOrders: 0,
    selectedDateTime: new Date(),
    minDateTime: new Date(),
    maxDate: new Date(),
    maxTime: new Date(),
    minimumOrder: 0,
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);

    // call so that flag is set per the current window size
    this.handleResize();

    const cartItemsJSON = localStorage.getItem("cartItems") || "[]";
    const formDataJson = localStorage.getItem("formData") || "{}";
    let CartTime = localStorage.getItem("cartTime") || "{}";
    const deliverfeeJson = localStorage.getItem("locationName") || "{}";
    let OrderTypeJSON = localStorage.getItem("orderType") || 0;

    var items = [];
    let formObject = [];
    let deliveryObject = [];

    try {
      items = JSON.parse(cartItemsJSON);
      formObject = JSON.parse(formDataJson);
      deliveryObject = JSON.parse(deliverfeeJson);

      if (this.state.storeData.IsFutureOrderAllowed) {
        var max = new Date();
        max.setDate(
          max.getDate() + this.state.storeData.MaxDaysAllowedForFutureOrders
        );

        this.setState({
          isFutureOrderAllowed: true,
          maxDaysForFutureOrders:
            this.state.storeData.MaxDaysAllowedForFutureOrders,
          maxDate: max,
        });
        this.getMinMaxTime(new Date());
      }
    } catch (ex) {
      console.log(ex);
    }

    if (this.props.modeParam && this.props.modeParam.toUpperCase() === "D")
      OrderTypeJSON = 2; // set order type to drive-through

    // if cart data is available but it belongs to another store, clear cart items.
    if (items.length > 0) {
      if (items[0].StoreId !== this.state.storeData.StoreId) {
        items = [];
      } else {
        let checkTime = this.lessThanOneHourAgo(CartTime);

        if (checkTime === true) {
          clearLocalStorage();
          localStorage.clear("cartTime");
          items = [];
        } else {
          items.map((item) => this.addItemDiscount(item));
        }
      }
    }

    if (formObject) {
      this.setState({ formData: formObject });
    }

    let formDataState = this.state.formData;

    const deliveryFeesArray = this.state.storeData.StoreDeliveryFees;

    // if the store doesn't contain any area wise delivery fees  OR
    // delivery object is empty
    if (
      deliveryFeesArray.length === 0 ||
      Object.keys(deliveryObject).length === 0
    ) {
      // clear any previously store fees object
      deliveryObject = null;
    } else {
      // if stored delivery object no longer exists in the system
      if (
        deliveryFeesArray.findIndex(
          (x) => x.DeliveryFeesId === deliveryObject.DeliveryFeesId
        ) === -1
      )
        // clear any previously store fees object
        deliveryObject = null;
    }

    if (deliveryObject) {
      this.setState({ deliveryFeesObject: deliveryObject });
      const minOrder =
        deliveryObject.MinOrderValue && deliveryObject.MinOrderValue > 0
          ? deliveryObject.MinOrderValue
          : this.state.storeData.MinimumOrder;
      formDataState = {
        ...formDataState,
        deliveryCharge: deliveryObject.DeliveryFees,
        LocationName: deliveryObject.LocationName,
        minimumOrder: minOrder,
      };
      this.setState({ formData: formDataState });
      this.setState({ minimumOrder: minOrder });
    } else {
      formDataState = {
        ...formDataState,
        deliveryCharge: this.state.storeData.DeliveryCharges,
        minimumOrder: this.state.storeData.MinimumOrder,
      };

      this.setState({ minimumOrder: this.state.storeData.MinimumOrder });
    }

    this.setState({ orderType: Number(OrderTypeJSON) });
    this.setState({ cartItems: items, isLoading: false }, () =>
      this.calculateCartTotals()
    );

    console.log("component did mount");
  }

  getMinMaxTime = (dateObj) => {
    if (moment(dateObj).isSame(moment(new Date()), "day")) {
      this.setState({ minDateTime: new Date() });
    } else {
      if (this.state.storeData.StoreTiming[dateObj.getDay()]) {
        var startTime = new Date(
          this.state.storeData.StoreTiming[dateObj.getDay()].StartTime
        );

        startTime.setDate(dateObj.getDate());
        startTime.setMonth(dateObj.getMonth());
        startTime.setFullYear(dateObj.getFullYear());
        this.setState({ minDateTime: startTime });
      } else {
        var startTime = new Date(dateObj);
        startTime.setHours(0);
        startTime.setMinutes(0);
        startTime.setSeconds(0);
        this.setState({ minDateTime: startTime });
      }
    }

    //setting the max time for both cases(1. when selected date = today
    //2. when selected date != today)
    if (this.state.storeData.StoreTiming[dateObj.getDay()]) {
      var endTime = new Date(
        this.state.storeData.StoreTiming[dateObj.getDay()].EndTime
      );
      dateObj.setHours(endTime.getHours());
      dateObj.setMinutes(endTime.getMinutes());
      this.setState({ maxTime: new Date(dateObj) });
    } else {
      var endTime = new Date(dateObj);
      endTime.setHours(23);
      endTime.setMinutes(59);
      endTime.setSeconds(59);
      this.setState({ maxTime: new Date(endTime) });
    }
  };

  handleResize = () => {
    this.setState({ hideCartColumn: window.innerWidth < 768 });
  };

  lessThanOneHourAgo = (date) => {
    const HOUR = 1000 * 60 * 60;
    const anHourAgo = Date.now() - HOUR;
    const getDate = Date.parse(date);
    return getDate < anHourAgo;
  };

  handleOrderType = (data, event) => {
    this.setState({ orderType: data });
  };

  handleOrderTypeCart = (event, data) => {
    if (data != null) {
      this.setState({ orderType: data });
      localStorage.setItem("orderType", data);
    }
  };

  checkCartQty = () => {
    let cartItems = this.state.cartItems;
    let qty = cartItems.length;
    cartItems.map((data) => {
      if (data.Count > 1) {
        qty += data.Count - 1;
      }
      return null;
    });
    return qty;
  };

  checkDiscountOffer = (subTotal, disc) => {
    let Amount = subTotal;

    this.state.cartItems.map((cartItem) => {
      this.state.checkItemDiscount.map((item) => {
        if (item.MenuItemId === cartItem.MenuItemId) {
          Amount -= cartItem.ItemTotal;
        }
        // return null;
      });
      // return null;
    });

    let filterOfferMinimum = disc.filter((y) => (!y.MinimumAmount ? null : y));
    let filterOffer = disc.filter((y) => (!y.MinimumAmount ? y : null));

    if (filterOffer.length > 0) {
      if (filterOffer && filterOffer[0].Amount) {
        return filterOffer[0].Amount;
      } else if (filterOffer && filterOffer[0].Percentage) {
        return (Amount / 100) * filterOffer[0].Percentage;
      } else {
        return 0;
      }
    } else if (filterOfferMinimum.length > 0) {
      let check = [];
      filterOfferMinimum.map((a) =>
        subTotal >= a.MinimumAmount ? check.push(true) : check.push(false)
      );
      let offer = filterOfferMinimum[check.filter(Boolean).length - 1];
      if (offer && offer.Amount) {
        return offer.Amount;
      } else if (offer && offer.Percentage) {
        return (Amount / 100) * offer.Percentage;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  getCartItems = () => {
    return this.state.cartItems.slice();
  };

  addItemDiscount = (item) => {
    if (item.OfferAmount > 0) {
      this.setState({
        itemDiscount: this.state.itemDiscount + item.OfferAmount,
      });
    } else if (item.OfferPercent > 0) {
      this.setState({
        itemDiscount:
          this.state.itemDiscount +
          Math.round((item.Price / 100) * item.OfferPercent),
      });
    }
  };

  addToCartClicked = (item) => {
    if (
      this.state.showQuestions ||
      this.state.showAddress ||
      this.state.isFinalizeOrder ||
      //this.state.showFinalizeOrder || ()
      this.state.showOrderSuccess ||
      this.state.storeData.IsClosed
    )
      return;

    if (item.Questions.length > 0) {
      let itemAnswers = [];

      item.Questions.forEach((q) => {
        itemAnswers.push(new Answer(q.Question, q.ShortName, ""));
      });

      // Send answers assuming quantity one
      this.setState({
        showQuestionPopup: true,
        itemToAdd: { ...item, Answers: itemAnswers },
      });
    } else {
      this.addItemToCart(item);
    }
  };

  handleDiscountStore = (check) => {
    let checkDiscountArray = this.state.checkItemDiscount;
    checkDiscountArray.push(check);
    checkDiscountArray = [...new Set(checkDiscountArray)];
    this.setState({ checkItemDiscount: checkDiscountArray });
  };

  addItemToCart = (item) => {
    const crntCartItems = this.state.cartItems;
    let time = new Date();

    let alreadyExists = false;

    crntCartItems.forEach((x) => {
      if (x.MenuItemId === item.MenuItemId) {
        alreadyExists = true;
        x.Count++;
        x.ItemTotal = x.Price * x.Count;
      }
    });

    if (!alreadyExists) {
      crntCartItems.push({
        ...item,
        Count: 1,
        ItemTotal: item.Price,
        Answers: [],
      });
    }
    localStorage.setItem("cartTime", time);
    this.addItemDiscount(item);
    updateLocalStorage(crntCartItems);
    this.props.refreshMenu();
    this.setState({ cartItems: crntCartItems }, () =>
      this.calculateCartTotals()
    );
  };

  increaseCount(item) {
    item.Count++;
    item.ItemTotal = item.Price * item.Count;
    this.addItemDiscount(item);
    updateLocalStorage(this.state.cartItems);
    this.props.refreshMenu();
    this.setState({ toggle: !this.state.toggle }, () =>
      this.calculateCartTotals()
    );
  }

  decreaseCount(item) {
    const crntCartItems = this.state.cartItems;
    if (item.OfferAmount > 0) {
      this.setState({
        itemDiscount:
          this.state.itemDiscount > 0
            ? this.state.itemDiscount - item.OfferAmount
            : 0,
      });
    } else if (item.OfferPercent > 0) {
      this.setState({
        itemDiscount:
          this.state.itemDiscount > 0
            ? this.state.itemDiscount -
              Math.round((item.Price / 100) * item.OfferPercent)
            : 0,
      });
    }
    // this update is by reference so the cart will be updated
    if (item.Count > 1) {
      item.Count--;
      item.ItemTotal = item.Price * item.Count;
    } else {
      crntCartItems.splice(crntCartItems.indexOf(item), 1);
    }

    updateLocalStorage(crntCartItems);
    this.props.refreshMenu();
    this.setState({ toggle: !this.state.toggle }, () =>
      this.calculateCartTotals()
    );
  }

  clearCartNFlags = () => {
    this.setState({
      cartItems: [],
      showQuestions: false,
      showAddress: false,
      isFinalizeOrder: false,
      showOrderSuccess: true,
    });
  };

  getStoreDeliveryServiceFee = async () => {
    if (this.state.mapPosition.lat === 0) return -1;

    const fare = await getDeliveryFare(
      this.state.storeData.StoreId,
      this.state.mapPosition
    );

    console.log({ fare });

    return fare.MaxFare;
  };

  calculateDelivery = async (storeData, deliveryFeesObject, subTotal) => {
    let deliveryFee = 0;

    // No charges if not delivery order
    if (this.state.orderType === 0) {
      if (storeData.UseDeliveryService)
        deliveryFee = await this.getStoreDeliveryServiceFee();
      else if (storeData.StoreDeliveryFees.length > 0) {
        if (Object.keys(deliveryFeesObject).length > 0)
          deliveryFee =
            !deliveryFeesObject.MinOrderValue ||
            deliveryFeesObject.MinOrderValue === 0
              ? deliveryFeesObject.DeliveryFees
              : subTotal > storeData.FreeDeliveryAmount &&
                storeData.FreeDeliveryAmount !== 0
              ? 0
              : deliveryFeesObject.DeliveryFees;
        else deliveryFee = storeData.DeliveryCharges;
      } else if (storeData.FreeDeliveryAmount === 0)
        deliveryFee = storeData.DeliveryCharges;
      else
        deliveryFee =
          subTotal > storeData.FreeDeliveryAmount
            ? 0
            : storeData.DeliveryCharges;
    }

    return deliveryFee;
  };

  calculateCartTotals = async () => {
    const storeData = this.state.storeData;
    const items = this.state.cartItems.slice();
    const deliveryFeesObject = this.state.deliveryFeesObject;

    let discount = this.state.itemDiscount;
    let subTotal = 0;

    items.forEach((x) => {
      subTotal += x.ItemTotal;
      x.Answers.map((y) => {
        if (y.Price) {
          subTotal += y.Price * x.Count;
        }
      });
    });

    const taxAmount = Math.round((subTotal * storeData.TaxPercent) / 100);

    discount += Math.round(this.checkDiscountOffer(subTotal, storeData.Offer));

    this.setState({
      subTotal: subTotal,
      taxAmount: taxAmount,
      discount: discount,
    });

    const delivery = await this.calculateDelivery(
      storeData,
      deliveryFeesObject,
      subTotal
    );

    let cartTotal = 0;

    if (subTotal > 0)
      if (delivery === -1 || delivery === -999)
        cartTotal = subTotal + taxAmount - discount;
      else cartTotal = subTotal + taxAmount - discount + delivery;
    else cartTotal = 0;

    this.setState({
      delivery: delivery,
      cartTotal: cartTotal,
    });
  };

  getCurrentGeolocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.setState(
          {
            mapPosition: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          },
          () => {
            Geocode.fromLatLng(
              position.coords.latitude,
              position.coords.longitude
            ).then(
              (response) => {
                console.log(response);
                const address = response.results[0].formatted_address;
                const formData = this.state.formData;

                // DB address max length is 100
                this.setState({
                  formData: { ...formData, address: address.substring(0, 100) },
                });
              },
              (error) => {
                console.error(error);
              }
            );
          }
        );
      });
    } else {
      console.error("Geolocation is not supported by this browser!");
    }
  };

  getGeoLocationForAddress = () => {
    const address =
      this.state.formData.address +
      " " +
      this.state.storeData.City +
      " " +
      this.state.storeData.Country;

    Geocode.fromAddress(address).then(
      (response) => {
        this.setState({ mapPosition: response.results[0].geometry.location });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  handleDeliveryTimeTypeChange = (event) => {
    this.setState({ deliveryTime: event.target.value });
  };

  handleDateTimeChange = (newValue) => {
    var selectedDate = new Date(newValue);
    selectedDate.setSeconds(0);
    this.setState({ selectedDateTime: selectedDate });
    this.getMinMaxTime(new Date(selectedDate));
  };

  drawCart = () => {
    const storeData = this.state.storeData;
    const items = this.state.cartItems.slice();
    //const deliveryFeesObject = this.state.deliveryFeesObject;

    const delivery = this.state.delivery;
    const subTotal = this.state.subTotal;
    const discount = this.state.discount;
    const taxAmount = this.state.taxAmount;
    const cartTotal = this.state.cartTotal;

    const cartItemsBlock = items.map((item) => (
      <div key={item.MenuItemId} className="item-order">
        <div className="item-details">
          <div className="item-name">
            {item.Count} x {item.Name}
          </div>
          <div className="item-price">
            {item.Currency} {formatPrice(item.ItemTotal)}
          </div>
        </div>
        <div className="answer-details" id="answer-details">
          <ul>
            {item.Answers.map((x, aIndex) =>
              x.Price ? (
                <li
                  key={"item-" + item.MenuItemId + "-" + aIndex}
                  className="answers color"
                >
                  <Grid container>
                    <Grid item xs={8}>
                      <p>{x.shortName + ": " + x.answer}</p>
                    </Grid>
                    <Grid item xs={4}>
                      <p style={{ textAlign: "right" }}>
                        {item.Currency} {formatPrice(x.Price * item.Count)}
                      </p>
                    </Grid>
                  </Grid>
                </li>
              ) : (
                <li
                  key={"item-" + item.MenuItemId + "-" + aIndex}
                  className="answers color"
                >
                  {x.shortName + ": " + x.answer}
                </li>
              )
            )}
          </ul>
        </div>
        <div className="order-details">
          <IconButton
            onClick={() => this.decreaseCount(item)}
            color="secondary"
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
          <span className="item-count">{item.Count}</span>
          <IconButton onClick={() => this.increaseCount(item)} color="primary">
            <AddCircleOutlineIcon />
          </IconButton>
        </div>
      </div>
    ));

    let checkMinimum = false;

    if (subTotal >= this.state.minimumOrder) {
      checkMinimum = true;
    }

    return (
      <div className="shopping-cart">
        {this.state.hideCartColumn ? null : (
          <>
            <div className="row cart-header">
              <Grid
                container
                justify="center"
                className="toggle-button"
                style={{ paddingLeft: 15, paddingRight: 15 }} // important
              >
                <ToggleButtonGroup
                  value={this.state.orderType}
                  exclusive
                  onChange={this.handleOrderTypeCart}
                  aria-label="text alignment"
                  className="w-100"
                >
                  <ToggleButton
                    value={0}
                    style={{ width: "33%" }}
                    aria-label="left aligned"
                  >
                    <p>Delivery</p>
                  </ToggleButton>
                  <ToggleButton
                    value={1}
                    style={{ width: "33%" }}
                    aria-label="right aligned"
                  >
                    <p>Pick up</p>
                  </ToggleButton>
                  <ToggleButton
                    value={2}
                    style={{ width: "34%" }}
                    aria-label="right aligned"
                  >
                    <p>Drive-Thru</p>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <div className="col-12 color">
                <h3>Your Order</h3>
              </div>
            </div>
            <hr />
          </>
        )}
        <div
          className={`row ${
            cartItemsBlock.length !== 0 ? "cart-details" : "cart-details-empty"
          }`}
        >
          <div className="col-12 ">
            <div className="item-list">
              {cartItemsBlock.length === 0 ? (
                <div className="text-center">
                  <img src={cartImg} className="img-fluid" alt="cartImage" />
                  <h3 className="color">Start Ordering Now</h3>
                </div>
              ) : (
                cartItemsBlock
              )}
            </div>
          </div>
        </div>

        {cartItemsBlock.length > 0 ? (
          <div className="pt-3">
            <div className="row cart-footer">
              <div className="col-12">
                <div className="pricing-section">
                  <div className="price-detail draw-line">
                    <div className="price-name">Sub Total</div>
                    <div className="price-value">
                      {this.cartCurrency} {formatPrice(subTotal)}
                    </div>
                  </div>
                  <div className="price-detail draw-line">
                    <div className="price-name ">Discount</div>
                    <div className="price-value">
                      {this.cartCurrency} {formatPrice(discount)}
                    </div>
                  </div>
                  <div className="price-detail draw-line">
                    <div className="price-name">Tax</div>
                    <div className="price-value">
                      {this.cartCurrency} {formatPrice(taxAmount)}
                    </div>
                  </div>
                  <div className="price-detail draw-line">
                    <div className="price-name">Delivery Fees</div>
                    <div className="price-value">
                      {delivery === -1
                        ? "TBD"
                        : delivery === -999
                        ? "ERR"
                        : this.cartCurrency + " " + formatPrice(delivery)}
                    </div>
                  </div>
                  <div className="price-detail">
                    <div className="price-name total-title color">Total</div>
                    <div className="price-value total-value color">
                      {this.cartCurrency + " " + formatPrice(cartTotal)}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {!this.state.storeData.IsClosed && (
              <div className="row cart-checkout mt-2">
                <div className="col-12">
                  {checkMinimum ? (
                    <Button
                      variant="contained"
                      color="primary"
                      className="cart_btn"
                      fullWidth
                      onClick={() => this.getAddress()}
                    >
                      Place Order
                    </Button>
                  ) : (
                    <p style={{ marginTop: 30, color: "red" }}>
                      Minimum order amount should be {storeData.Currency}{" "}
                      {formatPrice(this.state.minimumOrder)} is required
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  drawAddress = () => {
    const cartItems = this.state.cartItems.filter((x) => x.Count > 0).slice();
    if (cartItems.length === 0) return;

    const formData = this.state.formData;
    const deliveryFees = this.state.storeData.StoreDeliveryFees.slice();
    const deliveryFeesObject = this.state.deliveryFeesObject;

    if (this.state.mapPosition.lat === 0) this.getGeoLocationForAddress();

    return (
      <div className="delivery-address">
        <Grid container justify="center" className="toggle-button">
          <ToggleButtonGroup
            value={this.state.orderType}
            exclusive
            onChange={this.handleOrderTypeCart}
            aria-label="text alignment"
            className="w-100"
          >
            <ToggleButton
              value={0}
              style={{ width: "33%" }}
              aria-label="left aligned"
              disabled
            >
              <p>Delivery</p>
            </ToggleButton>
            <ToggleButton
              value={1}
              style={{ width: "33%" }}
              aria-label="right aligned"
              disabled
            >
              <p>Pick up</p>
            </ToggleButton>
            <ToggleButton
              value={2}
              style={{ width: "34%" }}
              aria-label="right aligned"
              disabled
            >
              <p>Drive-Thru</p>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        {this.state.orderType === 0 && (
          <div className="mb-3 color">Delivery Details</div>
        )}
        {this.state.orderType === 1 && (
          <div className="mb-3 color">Pick Up Details</div>
        )}
        {this.state.orderType === 2 && (
          <div className="mb-3 color">Drive-Thru Details</div>
        )}
        <ValidatorForm
          className="address-form"
          onSubmit={this.handleAddressSubmit}
          onError={(errors) => console.log(errors)}
        >
          {this.state.orderType === 0 && (
            <div className="form-section">
              <TextValidator
                name="name"
                label="Your Name"
                variant="outlined"
                value={formData.name}
                fullWidth
                validators={["required"]}
                errorMessages={["Your Name is required"]}
                onChange={this.handleChange}
                className="addresscontrol"
              />
              <TextValidator
                name="phone"
                label="Phone Number"
                variant="outlined"
                value={formData.phone}
                fullWidth
                validators={["required"]}
                errorMessages={["Phone number is required"]}
                onChange={this.handleChange}
                className="addresscontrol"
              />
              <TextValidator
                name="email"
                label="Email"
                variant="outlined"
                value={formData.email}
                fullWidth
                validators={["isEmail"]}
                errorMessages={["Email is not valid"]}
                onChange={this.handleChange}
                className="addresscontrol"
              />

              {deliveryFees.length > 0 && (
                <SelectValidator
                  name="deliveryCharge"
                  label="Area"
                  fullWidth
                  variant="outlined"
                  validators={["required"]}
                  errorMessages={["Area is required"]}
                  value={
                    Object.keys(deliveryFeesObject).length > 0
                      ? "fee-" +
                        deliveryFeesObject.DeliveryFeesId +
                        "-" +
                        deliveryFeesObject.DeliveryFees
                      : null
                  }
                >
                  {deliveryFees.map((fee) => (
                    <MenuItem
                      key={"fee-" + fee.DeliveryFeesId}
                      value={
                        "fee-" + fee.DeliveryFeesId + "-" + fee.DeliveryFees
                      }
                      onClick={() => this.handleSelectLocation(fee)}
                    >
                      {fee.LocationName}
                    </MenuItem>
                  ))}
                </SelectValidator>
              )}

              <TextValidator
                name="address"
                label="Delivery Address"
                value={formData.address}
                variant="outlined"
                validators={["required"]}
                errorMessages={["Address is required"]}
                onChange={this.handleChange}
                onBlur={(e) => this.getGeoLocationForAddress()}
                className="addresscontrol"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <FontAwesomeIcon
                      className="mr-2"
                      icon={faCrosshairs}
                      size="1x"
                      style={{
                        color: "rgba(240, 90, 40, 1)",
                        width: 20,
                        cursor: "pointer",
                      }}
                      onClick={() => this.getCurrentGeolocation()}
                    />
                  ),
                }}
              />

              <LocationMap position={this.state.mapPosition} />

              <TextValidator
                name="instructions"
                label="Any Instructions"
                variant="outlined"
                value={formData.instructions}
                fullWidth
                multiline
                onChange={this.handleChange}
                className="addresstextcontrol"
              />
              <RadioGroup
                className="pay-method color"
                defaultValue="cod"
                name="paymentmethod"
              >
                <FormControlLabel
                  value="cod"
                  disabled
                  control={<Radio />}
                  label="Cash on delivery"
                />
              </RadioGroup>
            </div>
          )}

          {this.state.orderType === 1 && (
            <div className="form-section">
              <TextValidator
                name="name"
                label="Your Name"
                variant="outlined"
                value={formData.name}
                fullWidth
                validators={["required"]}
                errorMessages={["Your Name is required"]}
                onChange={this.handleChange}
                className="addresscontrol"
              />
              <TextValidator
                name="phone"
                label="Phone Number"
                variant="outlined"
                value={formData.phone}
                fullWidth
                validators={["required"]}
                errorMessages={["Phone number is required"]}
                onChange={this.handleChange}
                className="addresscontrol"
              />
              <TextValidator
                name="instructions"
                label="Any Instructions"
                variant="outlined"
                value={formData.instructions}
                fullWidth
                multiline
                onChange={this.handleChange}
                className="addresstextcontrol"
              />
            </div>
          )}

          {this.state.orderType === 2 && (
            <div className="form-section">
              <TextValidator
                name="name"
                label="Your Name"
                variant="outlined"
                value={formData.name}
                fullWidth
                validators={["required"]}
                errorMessages={["Your Name is required"]}
                onChange={this.handleChange}
                className="addresscontrol"
              />
              <TextValidator
                name="phone"
                label="Phone Number"
                variant="outlined"
                value={formData.phone}
                fullWidth
                validators={["required"]}
                errorMessages={["Phone number is required"]}
                onChange={this.handleChange}
                className="addresscontrol"
              />
              <TextValidator
                name="instructions"
                label="Car License Plate #"
                variant="outlined"
                value={formData.instructions}
                fullWidth
                validators={["required"]}
                errorMessages={["License Plate # is required"]}
                onChange={this.handleChange}
                className="addresscontrol"
              />
            </div>
          )}
          {this.state.isFutureOrderAllowed && (
            <RadioGroup
              className="pay-method color"
              defaultValue="now"
              name="deliverytime"
              row
              value={this.state.deliveryTime}
              onChange={this.handleDeliveryTimeTypeChange}
            >
              <FormControlLabel value="now" control={<Radio />} label="Now" />
              <FormControlLabel
                value="later"
                control={<Radio />}
                label="Later"
              />
            </RadioGroup>
          )}
          {this.state.deliveryTime === "later" && (
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MobileDateTimePicker
                label="Delivery Date & Time"
                inputFormat="DD-MMM-yyyy LT"
                value={this.state.selectedDateTime}
                minDate={moment(new Date())}
                minTime={moment(this.state.minDateTime)}
                maxDate={moment(this.state.maxDate, "YYYYMMDD")}
                maxTime={moment(this.state.maxTime, "h:mm a")}
                onChange={this.handleDateTimeChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          )}
          <div style={{ display: "flex" }}>
            <Button
              type="button"
              onClick={() => this.cancelAddress()}
              variant="contained"
              color="secondary"
              className="go_back"

              // fullWidth
            >
              Go Back
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="cart_btn"
              // fullwidth
              style={{ marginLeft: "2%" }}
            >
              Finalize Order
            </Button>
          </div>
        </ValidatorForm>
      </div>
    );
  };

  handleSelectLocation = (fee) => {
    this.setState({ deliveryFeesObject: fee });
    localStorage.setItem("locationName", JSON.stringify(fee));
    const minOrder =
      fee.MinOrderValue && fee.MinOrderValue > 0
        ? fee.MinOrderValue
        : this.state.storeData.MinimumOrder;

    this.setState({ minimumOrder: minOrder });
    const newFormData = this.state.formData;
    newFormData.deliveryCharge = fee.DeliveryFees;
    newFormData.LocationName = fee.LocationName;
    newFormData.minimumOrder = minOrder;
    this.setState({ formData: newFormData });

    localStorage.setItem("formData", JSON.stringify(newFormData));
  };

  handleChange = (event) => {
    const { formData } = this.state;

    formData[event.target.name] = event.target.value;
    this.setState({ formData });

    localStorage.setItem("formData", JSON.stringify(formData));
  };

  handleAddressSubmit = (event) => {
    this.getGeoLocationForAddress();
    this.calculateCartTotals();

    this.finalizeOrder();
    event.preventDefault();
  };

  handleFinalizeSubmit = (event) => {
    const cartItems = this.state.cartItems.filter((x) => x.Count > 0).slice();
    const storeData = this.state.storeData;
    const formData = this.state.formData;
    const deliveryFeesObject = this.state.deliveryFeesObject;

    const delivery = this.state.delivery;
    const subTotal = this.state.subTotal;
    const discount = this.state.discount;
    const taxAmount = this.state.taxAmount;
    const cartTotal = this.state.cartTotal;

    if (cartItems.length > 0) {
      this.setState({ isPlacingOrder: true });

      const orderItems = cartItems.map((item) => ({
        MenuItemId: item.MenuItemId,
        ActualPrice: item.Price,
        Quantity: item.Count,
        TotalPrice: item.ItemTotal,
        FinalPrice: item.ItemTotal,
        Answers: item.Answers,
      }));

      const storeOrder = {
        storeId: storeData.StoreId,
        orderType: this.state.orderType,
        orderSource: 1,
        deliveryCharge: delivery,
        total: subTotal,
        tax: taxAmount,
        discountPercent: 0,
        discountAmount: discount,
        netPayable: cartTotal,
        orderItem: orderItems,
        remarks: formData.instructions,
        deliveryAddress: {
          customerName: formData.name,
          phoneNumber: formData.phone,
          email: formData.email,
          streetAddress: this.state.orderType === 0 ? formData.address : "", // avoid sending junk
          deliveryArea: this.state.orderType === 0 ? formData.LocationName : "",
          cityId: storeData.CityId,
          countryId: storeData.CountryId,
          AddressGeoLocation: JSON.stringify(this.state.mapPosition),
        },
        IsFuture: this.state.deliveryTime === "later" ? true : false,
        FutureDate:
          this.state.deliveryTime === "later"
            ? this.state.selectedDateTime
            : null,
      };

      placeOrder(storeOrder).then((orderData) => {
        this.successStoreOrder = orderData;

        this.setState({ isPlacingOrder: false });

        if (orderData.OrderId === -1) {
          alert(
            "Unexpected error occured while placing your order. Please contact the restaurant."
          );
        } else {
          clearLocalStorage();
          localStorage.removeItem("orderType");

          this.clearCartNFlags();
          this.props.refreshMenu();
        }
      });
    } else alert("You do not have any items in your cart");
  };

  drawFinalOrder = () => {
    const cartItems = this.state.cartItems.filter((x) => x.Count > 0).slice();
    const storeData = this.state.storeData;
    const formData = this.state.formData;
    const isPlacingOrder = this.state.isPlacingOrder;
    const deliveryFeesObject = this.state.deliveryFeesObject;

    const delivery = this.state.delivery;
    const subTotal = this.state.subTotal;
    const discount = this.state.discount;
    const taxAmount = this.state.taxAmount;
    const cartTotal = this.state.cartTotal;

    const buttonText = isPlacingOrder ? "Saving Order" : "Confirm Order";

    console.log("Position: ", this.state.mapPosition);

    if (cartItems.length > 0) {
      const cartItemsBlock = cartItems.map((item) => (
        <div key={item.MenuItemId} className="item-order">
          <div className="item-details">
            <div className="item-name">
              {item.Count} x {item.Name}
            </div>
            <div className="item-price">
              {item.Currency} {formatPrice(item.ItemTotal)}
            </div>
          </div>
          <div className="answer-details">
            <ul>
              {item.Answers.map((x, aIndex) =>
                x.Price ? (
                  <li
                    key={"item-" + item.MenuItemId + "-" + aIndex}
                    className="answers color"
                  >
                    <Grid container>
                      <Grid item xs={8}>
                        <p>{x.shortName + ": " + x.answer}</p>
                      </Grid>
                      <Grid item xs={4}>
                        <p style={{ textAlign: "right" }}>
                          {item.Currency} {formatPrice(x.Price * item.Count)}
                        </p>
                      </Grid>
                    </Grid>
                  </li>
                ) : (
                  <li
                    key={"item-" + item.MenuItemId + "-" + aIndex}
                    className="answers color"
                  >
                    {x.shortName + ": " + x.answer}
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      ));

      return (
        <div className="shopping-cart">
          <Grid container justify="center" className="toggle-button">
            <ToggleButtonGroup
              value={this.state.orderType}
              exclusive
              onChange={this.handleOrderTypeCart}
              aria-label="text alignment"
              className="w-100"
            >
              <ToggleButton
                value={0}
                style={{ width: "33%" }}
                aria-label="left aligned"
                disabled
              >
                <p>Delivery</p>
              </ToggleButton>
              <ToggleButton
                value={1}
                style={{ width: "33%" }}
                aria-label="right aligned"
                disabled
              >
                <p>Pick up</p>
              </ToggleButton>
              <ToggleButton
                value={2}
                style={{ width: "34%" }}
                aria-label="right aligned"
                disabled
              >
                <p>Drive-Thru</p>
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <div className="row cart-header">
            <div className="col-12">
              <div className="text-center font-weight-bold color">
                Order Confirmation
              </div>
              <hr />
              {this.state.orderType === 0 && (
                <div className="text-center font-weight-bold">
                  Delivery Details
                </div>
              )}
              {this.state.orderType === 1 && (
                <div className="text-center font-weight-bold">
                  Pick Up Details
                </div>
              )}
              {this.state.orderType === 2 && (
                <div className="text-center font-weight-bold">
                  Drive-Thru Details
                </div>
              )}
            </div>
          </div>

          {this.state.orderType === 0 ? (
            <div className="customer-details">
              <div className="detail-row">
                <div className="label">Name</div>
                <div className="value">{formData.name}</div>
              </div>
              <div className="detail-row">
                <div className="label">Phone</div>
                <div className="value">{formData.phone}</div>
              </div>
              <div className="detail-row">
                <div className="label">Email</div>
                <div className="value">{formData.email}</div>
              </div>
              <div className="detail-row">
                <div className="label">Address</div>
                <div className="value">{formData.address}</div>
              </div>
              {formData.LocationName && formData.LocationName.length > 0 && (
                <div className="detail-row">
                  <div className="label">Delivery Area</div>
                  <div className="value">{formData.LocationName}</div>
                </div>
              )}
              <div className="detail-row">
                <div className="label">Instructions</div>
                <div className="value">{formData.instructions}</div>
              </div>

              {this.state.storeData.UseDeliveryService && (
                <div>
                  <hr />
                  <p style={{ fontWeight: "bold" }}>
                    Delivery is fulfilled through{" "}
                    {this.state.storeData.DeliveryServiceName}
                  </p>
                  <hr />
                </div>
              )}
            </div>
          ) : (
            <div className="customer-details">
              <div className="detail-row">
                <div className="label">Name</div>
                <div className="value">{formData.name}</div>
              </div>
              <div className="detail-row">
                <div className="label">Phone</div>
                <div className="value">{formData.phone}</div>
              </div>

              <div className="detail-row">
                <div className="label">Instructions</div>
                <div className="value">{formData.instructions}</div>
              </div>
            </div>
          )}
          {this.state.deliveryTime === "later" && (
            <div className="detail-row">
              {this.state.orderType === 0 ? (
                <div className="label">Delivery Date & Time</div>
              ) : (
                <div className="label">Pickup Date & Time</div>
              )}
              <div className="value">
                {moment(this.state.selectedDateTime).format("DD-MMM-yyyy LT")}
              </div>
            </div>
          )}
          <div className="text-center font-weight-bold ">
            <h5>Your Order</h5>
          </div>

          <div className="row cart-details finalize-cart">
            <div className="col-12">
              <div className="item-list">{cartItemsBlock}</div>
            </div>
          </div>
          <div className="pt-3">
            <div className="row cart-footer">
              <div className="col-12">
                <div className="pricing-section">
                  <div className="price-detail draw-line">
                    <div className="price-name">Sub Total</div>
                    <div className="price-value">
                      {this.cartCurrency} {formatPrice(subTotal)}
                    </div>
                  </div>
                  <div className="price-detail draw-line">
                    <div className="price-name">Discount</div>
                    <div className="price-value">
                      {this.cartCurrency} {formatPrice(discount)}
                    </div>
                  </div>
                  <div className="price-detail draw-line">
                    <div className="price-name">Tax</div>
                    <div className="price-value">
                      {this.cartCurrency} {formatPrice(taxAmount)}
                    </div>
                  </div>
                  <div className="price-detail draw-line">
                    <div className="price-name">Delivery Fees</div>
                    <div className="price-value">
                      {delivery === -1
                        ? "TBD"
                        : delivery === -999
                        ? "ERR"
                        : this.cartCurrency + " " + formatPrice(delivery)}
                    </div>
                  </div>
                  <div className="price-detail color">
                    <div className="price-name total-title">Total</div>
                    <div className="price-value total-value">
                      {this.cartCurrency + " " + formatPrice(cartTotal)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row cart-checkout mt-2">
              <div className="col-12">
                <div style={{ display: "flex" }}>
                  <Button
                    type="button"
                    onClick={() => this.cancelFinalize()}
                    variant="contained"
                    color="secondary"
                    className="go_back"
                  >
                    Go Back
                  </Button>
                  <Button
                    type="button"
                    onClick={() => this.handleFinalizeSubmit()}
                    variant="contained"
                    color="primary"
                    className="cart_btn delivery-details-btns"
                    style={{ marginLeft: "2%" }}
                    disabled={
                      isPlacingOrder || delivery === -999 || delivery === -1
                    }
                  >
                    {isPlacingOrder && (
                      <FontAwesomeIcon className="mr-2" icon={faSpinner} spin />
                    )}
                    {buttonText}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  drawOrderSuccess = () => {
    return (
      <div className="order-success">
        <div className="success-image">
          <FontAwesomeIcon
            className="text-success"
            icon={faCheckCircle}
            size="4x"
          />
        </div>
        <div className="header">Order Placed</div>
        <div className="success-details">
          <div className="detail-row">
            <div className="label">Order #</div>
            <div className="value">{this.successStoreOrder.OrderNumber}</div>
          </div>
          <div className="detail-row">
            <div className="label">Restaurant</div>
            <div className="value">{this.state.storeData.Name}</div>
          </div>
          <div className="detail-row">
            <div className="label">Name</div>
            <div className="value">
              {this.successStoreOrder.DeliveryAddress.CustomerName}
            </div>
          </div>
          <div className="detail-row">
            <div className="label">Phone</div>
            <div className="value">
              {this.successStoreOrder.DeliveryAddress.PhoneNumber}
            </div>
          </div>
          {this.state.orderType === 0 ? (
            <>
              <div className="detail-row">
                <div className="label">Email</div>
                <div className="value">
                  {this.successStoreOrder.DeliveryAddress.Email}
                </div>
              </div>
              <div className="detail-row">
                <div className="label">Address</div>
                <div className="value">
                  {this.successStoreOrder.DeliveryAddress.StreetAddress}
                </div>
              </div>

              {this.successStoreOrder.DeliveryAddress.DeliveryArea &&
                this.successStoreOrder.DeliveryAddress.DeliveryArea.length >
                  0 && (
                  <div className="detail-row">
                    <div className="label">Delivery Area</div>
                    <div className="value">
                      {this.successStoreOrder.DeliveryAddress.DeliveryArea}
                    </div>
                  </div>
                )}

              {this.state.storeData.UseDeliveryService && (
                <div>
                  <hr />
                  <p style={{ fontWeight: "bold" }}>
                    Delivery is fulfilled through{" "}
                    {this.state.storeData.DeliveryServiceName}
                  </p>
                  <hr />
                </div>
              )}
            </>
          ) : null}

          <div className="detail-row">
            <div className="label">Order Amount</div>
            <div className="value">
              {this.state.storeData.Currency}{" "}
              {formatPrice(this.successStoreOrder.NetPayable)}
            </div>
          </div>
          {this.state.deliveryTime === "later" && (
            <div className="detail-row">
              {this.state.orderType === 0 ? (
                <div className="label">Delivery Date & Time</div>
              ) : (
                <div className="label">Pickup Date & Time</div>
              )}
              <div className="value">
                {moment(this.state.selectedDateTime).format("DD-MMM-yyyy LT")}
              </div>
            </div>
          )}
        </div>
        <div className="pt-3">
          <Button
            variant="contained"
            color="primary"
            // fullWidth
            className="cart_btn"
            style={{ marginLeft: "2%" }}
            onClick={() => this.setState({ showOrderSuccess: false })}
          >
            Done
          </Button>
          {/* </div>
        <div className="pt-3"> */}
          <Button
            target="_blank"
            variant="contained"
            // fullWidth
            className="cart_btn track-btn"
            style={{ marginLeft: "2%", color: "white" }}
            href={
              "/orderstatus?ordernumber=" +
              this.successStoreOrder.TransactionNumber
            }
          >
            Track Order
          </Button>
        </div>
      </div>
    );
  };

  getAddress = () => {
    this.setState({ showAddress: true });
  };

  cancelAddress = () => {
    this.setState({ showAddress: false });
  };

  finalizeOrder = () => {
    this.setState({ showAddress: false, isFinalizeOrder: true });
  };

  cancelFinalize = () => {
    this.setState({ showAddress: true, isFinalizeOrder: false });
  };

  showModal = () => {
    this.setState({ showPopup: true });
  };

  hideModal = () => {
    this.setState({ showPopup: false });
  };

  handleQuestionPopupClose = () => {
    this.setState({ showQuestionPopup: false, itemToAdd: {} });
  };

  handleQuestionPopupSubmit = (itemToAdd) => {
    this.addItemDiscount(itemToAdd);
    let time = new Date();
    localStorage.setItem("cartTime", time);
    let crntCartItems = this.state.cartItems;

    crntCartItems.push({ ...itemToAdd });
    updateLocalStorage(crntCartItems);

    this.props.refreshMenu();

    this.setState(
      {
        showQuestionPopup: false,
        itemToAdd: {},
        cartItems: crntCartItems,
      },
      () => this.calculateCartTotals()
    );
  };

  render() {
    const isLoading = this.state.isLoading;
    const hideCartColumn = this.state.hideCartColumn;
    const showAddress = this.state.showAddress;
    const showFinalizeOrder = this.state.isFinalizeOrder;
    const showOrderSuccess = this.state.showOrderSuccess;
    const showQuestionPopup = this.state.showQuestionPopup;
    const itemToAdd = this.state.itemToAdd;

    if (isLoading)
      return (
        <div className="d-none d-md-block col-md-3 border-left border-secondary m-0 pt-3">
          {loading()}
        </div>
      );
    else {
      if (hideCartColumn)
        return (
          <div>
            {showQuestionPopup && (
              <ForcedQuestionPopup
                itemToAdd={itemToAdd}
                onAddToCart={this.handleQuestionPopupSubmit}
                onHandleClose={this.handleQuestionPopupClose}
              />
            )}

            <Modal show={this.state.showPopup} onHide={this.hideModal}>
              {showAddress || showOrderSuccess || showFinalizeOrder ? null : (
                <>
                  <Modal.Header>
                    <Grid container justify="center" className="toggle-button">
                      <ToggleButtonGroup
                        value={this.state.orderType}
                        exclusive
                        onChange={this.handleOrderTypeCart}
                        aria-label="text alignment"
                        className="w-100"
                      >
                        <ToggleButton
                          value={0}
                          style={{ width: "33%" }}
                          aria-label="left aligned"
                          disabled={
                            showAddress || showOrderSuccess || showFinalizeOrder
                              ? true
                              : false
                          }
                        >
                          <p>Delivery</p>
                        </ToggleButton>
                        <ToggleButton
                          value={1}
                          style={{ width: "33%" }}
                          aria-label="right aligned"
                          disabled={
                            showAddress || showOrderSuccess || showFinalizeOrder
                              ? true
                              : false
                          }
                        >
                          <p>Pick up</p>
                        </ToggleButton>
                        <ToggleButton
                          value={2}
                          style={{ width: "34%" }}
                          aria-label="right aligned"
                          disabled={
                            showAddress || showOrderSuccess || showFinalizeOrder
                              ? true
                              : false
                          }
                        >
                          <p>Drive-Thru</p>
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Grid>
                  </Modal.Header>

                  <Modal.Header closeButton>
                    <div className="color">
                      <h3>Your Order</h3>
                    </div>
                  </Modal.Header>
                </>
              )}
              <Modal.Body>
                {showAddress && this.drawAddress()}
                {showFinalizeOrder && this.drawFinalOrder()}
                {showOrderSuccess && this.drawOrderSuccess()}
                {!showAddress &&
                  !showFinalizeOrder &&
                  !showOrderSuccess &&
                  this.drawCart()}
              </Modal.Body>
            </Modal>

            <div className="cart-button-container">
              <div className="cart-button ">
                <Button
                  className="sticky-cart-button d-inline-flex flex-row justify-content-around"
                  variant="contained"
                  color="primary"
                  style={{ background: "rgba(240,90,40,1)" }}
                  onClick={this.showModal}
                >
                  <div>
                    <Badge
                      badgeContent={this.checkCartQty()}
                      color="primary"
                      className="products-qty"
                    />
                  </div>
                  <div>View Cart</div>
                  <div className="products-value">
                    {this.cartCurrency} {formatPrice(this.state.cartTotal)}
                  </div>
                </Button>
              </div>
            </div>
          </div>
        );
      else {
        return (
          <div className="d-none d-md-block col-md-3 border-left border-light-gray m-0 pt-3 shaddow">
            {showQuestionPopup && (
              <ForcedQuestionPopup
                itemToAdd={itemToAdd}
                onAddToCart={this.handleQuestionPopupSubmit}
                onHandleClose={this.handleQuestionPopupClose}
              />
            )}

            {showAddress && this.drawAddress()}
            {showFinalizeOrder && this.drawFinalOrder()}
            {showOrderSuccess && this.drawOrderSuccess()}
            {!showAddress &&
              !showFinalizeOrder &&
              !showOrderSuccess &&
              this.drawCart()}
          </div>
        );
      }
    }
  }
}
