import React from "react";
import ReceiptIcon from "@material-ui/icons/Receipt";
import DevicesIcon from "@material-ui/icons/Devices";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import MotorcycleIcon from "@material-ui/icons/Motorcycle";
import LinkIcon from "@material-ui/icons/Link";
import SupportAgent from "../../assets/support_agent-black-18dp.svg";
import Covid from "../../assets/coronavirus-black-18dp.svg";
const EatachiOffer = () => {
  return (
    <div className="offer-background" id="we-offer">
      <div className="container text-center">
        <h2 className="orange offer-section bold">What Eatachi Offers?</h2>
        <div className="row text-center ">
          <div className="col-xs-12 col-sm-6 col-md-4 offer-section">
            <ReceiptIcon className="orange icon-background" />
            <h5 className="bold">Double Your Sale</h5>
            <p>Online Ordering is the future of restaurant industry</p>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 offer-section">
            <img
              alt="logo"
              src={Covid}
              className="orange icon-background img-fluid"
            />
            <h5 className="bold">COVID 19 Proof</h5>
            <p>
              Don’t be afraid about the uncertainty of Lock Down & limited
              timings, continue your online business 24x7
            </p>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 offer-section">
            <DevicesIcon className="orange icon-background" />
            <h5 className="bold">Sale Acquisition</h5>
            <p>
              Engage your customer and get orders from all social media
              platforms including Google, Facebook, Twitter, Instagram
            </p>
          </div>

          <div className="col-xs-12 col-sm-6 col-md-4 offer-section">
            <MoneyOffIcon className="orange icon-background" />
            <h5 className="bold">No Commission</h5>
            <p>Why pay commission when you can get the orders for free</p>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 offer-section">
            <AddShoppingCartIcon className="orange icon-background" />
            <h5 className="bold">Unlimited Orders</h5>
            <p>No limitation on numbers of orders per day</p>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 offer-section">
            <MotorcycleIcon className="orange icon-background" />
            <h5 className="bold">Delivery Area Limits</h5>
            <p>
              Choose your own area limits, whether you want to delivery in whole
              city, nearby areas or your areas only.
            </p>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 offer-section">
            {/* <SupportAgent className="orange icon-background" /> */}
            <img
              alt="logo"
              src={SupportAgent}
              className="orange icon-background img-fluid"
            />
            <h5 className="bold">Technical Support</h5>
            <p>
              Don’t worry about technical issues, our 24x7 team is here to
              assist you with all queries to increase sales
            </p>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 offer-section">
            <LinkIcon className="orange icon-background " />
            <h5 className="bold">Your Domain Name</h5>
            <p>
              We provide URL starts from your own restuarant name which will
              help to increase search engines traffic
            </p>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 offer-section">
            <LinkIcon className="orange icon-background" />
            <h5 className="bold">Merge Your Site</h5>
            <p>
              Already have your own restaurant's website and want to run the
              ordering system with it ? Donot worry, you can use our Merge
              functionality to run Eatachi Ordering site with you existing
              website
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EatachiOffer;
