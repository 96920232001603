import { Component } from "react";

import { Nav, Navbar, NavLink } from "react-bootstrap";

import "./menu.css";
import { getMenuItems } from "../../api/service";
import { getMenuCard } from "../../helper";
import { getMenuCardTwo } from "../../helper";
import { loading } from "../loading/loading";

export default class Menu extends Component {
  storeData = this.props.storeData;

  sectionsList = [];
  menuLinks = [];

  state = {
    items: [],
  };

  BreakException = {};

  handleScroll = () => {
    if (this.sectionsList.length === 0)
      this.sectionsList = document.getElementsByClassName("menu-cat-section");

    let menu = document.getElementById("MenuBar");

    if (this.menuLinks.length === 0 && menu) {
      this.menuLinks = document.getElementById("MenuBar").children;
    }

    if (menu && this.menuLinks && this.sectionsList) {
      //Get menu height
      const navHeight = menu.offsetHeight - 150;

      //Store the top position of each section
      const sectionOffsetPosition = Array.prototype.map.call(
        this.sectionsList,
        (e) => e.offsetTop - navHeight
      );

      //Current scroll position
      const scrollPosition = window.pageYOffset;

      // console.log(this.menuLinks);
      // console.log(this.sectionsList);
      // console.log(sectionOffsetPosition);

      //Check the scroll position with the section's position
      //Add 'active' class to the menu link whose section is visible
      sectionOffsetPosition.forEach((e, i) => {
        if (e <= scrollPosition) {
          //Remove active class from all the links
          Array.prototype.forEach.call(this.menuLinks, (f) => {
            f.classList.remove("active");
          });

          //Add active to class to visible section link
          this.menuLinks[i].classList.add("active");

          this.menuLinks[i].scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "center",
          });
        }
      });
    }
  };

  componentDidMount() {
    getMenuItems(this.storeData.StoreId).then((response) => {
      this.setState({ items: response });
    });

    //Listen to the scroll event
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    //Remove the scroll listener
    window.removeEventListener("scroll", () => {});
  }

  refreshMenu = () => {
    //this.setState({ toggle: !this.state.toggle });
  };

  render() {
    // const menuItems = this.state.items.sort(
    //   (a, b) => a.DisplayOrder - b.DisplayOrder
    // );

    const menuItems = this.state.items;
    const storeOffer = this.storeData.Offer;
    const cartItems = []; //this.props.cartItems();

    if (menuItems.length === 0)
      return <div className="col-sm-12 col-md-9 w-100">{loading()}</div>;
    else
      return (
        <div className="col-sm-12 col-md-9 w-100 ">
          <Navbar
            id="MenuBar-Nav"
            bg="light"
            variant="light"
            expand
            className="background"
          >
            <Nav id="MenuBar" defaultActiveKey={0}>
              {menuItems.map((categoryItems, index) => (
                <NavLink
                  key={"link_" + categoryItems.MenuCategoryId}
                  href={"#catId_" + categoryItems.MenuCategoryId}
                  eventKey={index}
                >
                  {categoryItems.CategoryName}
                </NavLink>
              ))}
            </Nav>
          </Navbar>

          {menuItems.map((categoryItems) => (
            <div
              key={categoryItems.MenuCategoryId}
              className="menu-cat-section"
              id={"catId_" + categoryItems.MenuCategoryId}
            >
              <div className="row">
                <div className="col-12">
                  <h2>{categoryItems.CategoryName}</h2>
                </div>
              </div>
              <div className="row">
                {categoryItems.MenuItems.map((item) => {
                  const itemOrderCount = cartItems.reduce((prev, crnt) => {
                    if (crnt.ItemId === item.ItemId) return prev + crnt.Count;
                    else return prev;
                  }, 0);

                  if (this.storeData.DishDisplayFormatType === 2)
                    return getMenuCardTwo(
                      item,
                      this.props.addEvent,
                      storeOffer,
                      this.props.discountCheck,
                      itemOrderCount
                    );
                  else
                    return getMenuCard(
                      item,
                      this.props.addEvent,
                      storeOffer,
                      this.props.discountCheck,
                      itemOrderCount
                    );
                })}
              </div>
            </div>
          ))}
        </div>
      );
  }
}
