import React from "react";
import { Button } from "@material-ui/core";

import background from "../../assets/Image_1.png";
import outline from "../../assets/Outline.png";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { Nav } from "react-bootstrap";

import heroImage from "../../assets/hero-image.png";
import "./section.css";
const homeSection = () => {
  return (
    <div
      className="landing-image  d-flex"
      style={{ backgroundImage: `url(${background})` }}
      id="home"
    >
      <div className="outline">
        <div className="outline-social-top"></div>
        <div className="social-icon">
          <FacebookIcon className="blue" />
          <br />
          <InstagramIcon className="blue" />
          <br />
          <LinkedInIcon className="blue" />
        </div>
        <div className="outline-social-bottom"></div>
      </div>
      <div className="container landing">
        <div className="row no-gutters">
          <div className="col-sm-12 col-md-6 text-center">
            <h3 className="orange">DOUBLE YOUR</h3>
            <h1 className="blue">SALE & PROFIT</h1>
            <h5 className="bold">with Your Own</h5>
            <h1 className="orange bold">ONLINE ORDERING SYSTEM</h1>
            <div>
              <img src={outline} className="img-fluid" alt="bg" />
            </div>
            <div className="text-left align-middle">
              <h4>
                <CheckCircleOutlineIcon className="orange" /> NO SETUP{" "}
                <span className="orange">COST</span>
              </h4>
              <h4>
                <CheckCircleOutlineIcon className="orange" /> NO{" "}
                <span className="orange">MONTHLY</span> CHARGES
              </h4>
              <h4>
                <CheckCircleOutlineIcon className="orange" /> NO{" "}
                <span className="orange">COMMISSION</span> FEE
              </h4>
              <Nav.Link
                href="https://storeportal.eatachi.co/Register"
                target="_blank"
              >
                <Button
                  variant="contained"
                  color="primary"
                  className="OrderNow"
                >
                  SET ME UP
                </Button>
              </Nav.Link>

              <p className="blue normal text-left " style={{ marginTop: "2%" }}>
                {" "}
                Get Your Restuarant Business{" "}
                <span className="bold">ONLINE</span> in{" "}
                <span className="bold">1 Hour</span>
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img src={heroImage} className="img-fluid" alt="hero" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default homeSection;
