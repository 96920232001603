import React, { useState, useEffect } from "react";
import placeholder from "../../assets/placeholder.png";

export function LazyBackground(props) {
  const [source, setSource] = useState(placeholder);

  useEffect(() => {
    const img = new Image();
    img.src = props.src;
    img.onload = () => setSource(props.src);
  }, [props.src]);

  return (
    <div
      style={{
        backgroundImage: `url(${placeholder})`,
        height: !props.height ? "auto" : props.height,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div
        style={{
          backgroundImage: `url(${source})`,
          height: !props.height ? "auto" : props.height,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        {props.children}
      </div>
    </div>
  );
}
