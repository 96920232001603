import { Component, useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Moment from "react-moment";
import ReactGA from "react-ga";
import { getStoreInfo, formatPrice } from "../../helper";
import { loading } from "../loading/loading";
import { getOrderStatus } from "../../api/service";
import { Grid } from "@material-ui/core";

import Header from "../header/header";
import Footer from "../footer/footer";

import "./orderstatus.css";

export default class OrderStatus extends Component {
  state = {
    isLoading: true,
    storeData: { StoreId: 0 },
    resize: false,
    orderType: 0,
  };

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    window.addEventListener("resize", this.handleResize);
    // call so that flag is set per the current window size
    this.handleResize();
    getStoreInfo()
      .then((response) => {
        this.setState({ storeData: response });
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  }

  handleResize = () => {
    this.setState({ resize: window.innerWidth < 769 });
  };

  render() {
    const { isLoading, storeData, resize } = this.state;

    if (isLoading) return loading();
    else if (storeData.StoreId === 0) return <Redirect to="/" />;
    else {
      let queryString = this.props.location.search;
      return (
        <div className="container-fluid p-0">
          <Header id={storeData} />

          <div className="row m-0">
            {<Status queryString={queryString} resize={resize} />}
          </div>

          <Footer storeData={storeData} />
        </div>
      );
    }
  }
}

function Status(queryStr) {
  const [storeOrder, setStoreOrder] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [orderType, setOrderType] = useState(0);

  const [isReceived, setIsReceived] = useState("");
  const [isAccepted, setIsAccepted] = useState("");
  const [isReady, setIsReady] = useState("");
  const [isDispatched, setIsDispatched] = useState("");
  const [isDelivered, setIsDelivered] = useState("");
  const { resize } = queryStr;

  useEffect(() => {
    let orderTypeLocal = localStorage.getItem("orderType") || 0;
    setOrderType(Number(orderTypeLocal));
  }, []);

  useEffect(() => {
    const { queryString } = queryStr;

    let transNumber = "";

    if (queryString.includes("number")) transNumber = queryString.split("=")[1];

    if (transNumber && transNumber.length > 0) {
      getOrderStatus(transNumber)
        .then((orderStatus) => {
          console.log({ orderStatus });

          setStoreOrder(orderStatus);
          let received =
            orderStatus.OrderStatus.filter((x) => x.StatusId === 1).length > 0;
          let accepted =
            orderStatus.OrderStatus.filter((x) => x.StatusId === 2).length > 0;
          let ready =
            orderStatus.OrderStatus.filter((x) => x.StatusId === 5).length > 0;
          let dispatched =
            orderStatus.OrderStatus.filter((x) => x.StatusId === 6).length > 0;
          let delivered =
            orderStatus.OrderStatus.filter((x) => x.StatusId === 7).length > 0;

          received ? setIsReceived("done") : setIsReceived("");
          accepted > 0 ? setIsAccepted("done") : setIsAccepted("");
          ready > 0 ? setIsReady("done") : setIsReady("");
          dispatched > 0 ? setIsDispatched("done") : setIsDispatched("");
          delivered > 0 ? setIsDelivered("done") : setIsDelivered("");

          setIsLoading(false);
        })
        .catch(() => {
          setStoreOrder({ OrderId: -1 });
          setIsLoading(false);
        });
    } else {
      setStoreOrder({ OrderId: -1 });
      setIsLoading(false);
    }
  }, [queryStr]);

  return (
    <div className="col-12">
      <div className="order-status-head">Your Order Status</div>
      {isLoading && loading()}

      {storeOrder.OrderId < 1 && <div>Order number is invalid</div>}

      {storeOrder.OrderId > 0 && (
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center h3">{storeOrder.TypeDesc}</div>
          </div>
          <div className="row order-status">
            <div className="col-sm-6 customer-details">
              <div className="row detail-row">
                <div className="col-sm-3 label">Order #</div>
                <div className="col-sm-9 value">{storeOrder.OrderNumber}</div>
              </div>
              {storeOrder.DeliveryAddress && (
                <>
                  <div className="row detail-row">
                    <div className="col-sm-3 label">Name</div>
                    <div className="col-sm-9 value">
                      {storeOrder.DeliveryAddress.CustomerName}
                    </div>
                  </div>
                  <div className="row detail-row">
                    <div className="col-sm-3 label">Phone</div>
                    <div className="col-sm-9 value">
                      {storeOrder.DeliveryAddress.PhoneNumber}
                    </div>
                  </div>
                  {storeOrder.Type === 0 && (
                    <>
                      <div className="row detail-row">
                        <div className="col-sm-3 label">Address</div>
                        <div className="col-sm-9 value">
                          {storeOrder.DeliveryAddress.StreetAddress}
                        </div>
                      </div>
                      <div className="row detail-row">
                        <div className="col-sm-3 label">Delivery Area</div>
                        <div className="col-sm-9 value">
                          {storeOrder.DeliveryAddress.DeliveryArea}
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
              <div className="row detail-row">
                <div className="col-sm-3 label">
                  {storeOrder.Type === 2
                    ? "Car License Plate #"
                    : "Special Instructions"}
                </div>
                <div className="col-sm-9 value">{storeOrder.Remarks}</div>
              </div>
              <div className="row detail-row">
                <div className="col-sm-3 label">Order Time</div>
                <div className="col-sm-9 value">
                  <Moment format="DD-MMM-yyyy LT">{storeOrder.Date}</Moment>
                </div>
              </div>
              {storeOrder.IsFuture && (
                <div className="row detail-row">
                  {storeOrder.Type === 0 ? (
                    <div className="col-sm-3 label">Delivery Date & Time</div>
                  ) : (
                    <div className="col-sm-3 label">Pickup Date & Time</div>
                  )}
                  <div className="col-sm-9 value">
                    <Moment format="DD-MMM-yyyy LT">
                      {storeOrder.FutureDate}
                    </Moment>
                  </div>
                </div>
              )}
              {storeOrder.OrderRider && storeOrder.OrderRider.length > 0 && (
                <div className="row detail-row">
                  <div className="col-sm-3 label">Rider Assigned</div>
                  <div className="col-sm-9 value">
                    {storeOrder.OrderRider[0].RiderName}
                  </div>
                </div>
              )}
            </div>
            <div className="col-sm-6 order-details">
              {storeOrder.OrderItem.map((item) => (
                <div key={item.MenuItemId} className="item-order">
                  <div className="item-details">
                    <div className="item-name">
                      {item.Quantity} x {item.ItemName}
                    </div>
                    <div className="item-price">
                      {storeOrder.Currency} {formatPrice(item.FinalPrice)}
                    </div>
                  </div>
                  <div className="answer-details">
                    <ul>
                      {item.Answers.map((x, aIndex) =>
                        x.Price ? (
                          <li
                            key={"item-" + item.MenuItemId + "-" + aIndex}
                            className="answers color"
                          >
                            <Grid container>
                              <Grid item xs={8}>
                                <p
                                  style={{
                                    marginBottom: "0rem",
                                  }}
                                >
                                  {x.ShortName + ": " + x.Answer}
                                </p>
                              </Grid>
                              <Grid item xs={4}>
                                <p
                                  style={{
                                    textAlign: "right",
                                    marginBottom: "0rem",
                                  }}
                                >
                                  {` ${storeOrder.Currency} ${formatPrice(
                                    x.Price * item.Quantity
                                  )}`}
                                </p>
                              </Grid>
                            </Grid>
                          </li>
                        ) : (
                          <li
                            key={"item-" + item.MenuItemId + "-" + aIndex}
                            className="answers color"
                          >
                            {x.ShortName + ": " + x.Answer}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              ))}
              <div className="pricing-section">
                <div className="price-detail">
                  <div className="price-name">Sub Total</div>
                  <div className="price-value">
                    {storeOrder.Currency} {formatPrice(storeOrder.Total)}
                  </div>
                </div>
                <div className="price-detail">
                  <div className="price-name">Discount</div>
                  <div className="price-value">
                    {storeOrder.Currency}{" "}
                    {formatPrice(storeOrder.DiscountAmount)}
                  </div>
                </div>
                <div className="price-detail">
                  <div className="price-name">Tax</div>
                  <div className="price-value">
                    {storeOrder.Currency} {formatPrice(storeOrder.Tax)}
                  </div>
                </div>
                <div className="price-detail">
                  <div className="price-name">Delivery Fees</div>
                  <div className="price-value">
                    {storeOrder.Currency}{" "}
                    {formatPrice(storeOrder.DeliveryCharge)}
                  </div>
                </div>
                <div className="price-detail">
                  <div className="price-name total-title">Total</div>
                  <div className="price-value total-value">
                    {storeOrder.Currency} {formatPrice(storeOrder.NetPayable)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {storeOrder.Store.UseDeliveryService && (
            <div style={{ textAlign: "center" }}>
              <hr />
              <p style={{ fontWeight: "bold" }}>
                Delivery is fulfilled through{" "}
                {storeOrder.Store.DeliveryServiceName}
              </p>
              <hr />
            </div>
          )}
          <div className="row pt-4">
            <div className="col-12 d-flex justify-content-center">
              <div className="steps-row d-flex">
                <div className={resize ? "step-column width" : "step-column "}>
                  <span className={"step-number " + isReceived}></span>
                  <p className="step-label">Received</p>
                </div>

                <div className={resize ? "step-column width" : "step-column "}>
                  <span className={"step-number " + isAccepted}></span>
                  <p className="step-label">Accepted</p>
                </div>

                <div className={resize ? "step-column width" : "step-column "}>
                  <span className={"step-number " + isReady}></span>
                  <p className="step-label">Ready</p>
                </div>

                <div className={resize ? "step-column width" : "step-column "}>
                  <span className={"step-number " + isDispatched}></span>
                  <p className="step-label">Dispatched</p>
                </div>

                <div className={resize ? "step-column width" : "step-column "}>
                  <span className={"step-number " + isDelivered}></span>
                  <p className="step-label">Delivered</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
