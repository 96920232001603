import React from "react";

import eatachi_logo from "../../assets/Icon_in_Black-01.png";
import facebook from "../../assets/SM-FB.png";
import linkedin from "../../assets/SM-LI.png";
import Instagram from "../../assets/SM-IG.png";
import CallIcon from "@material-ui/icons/Call";
import MailIcon from "@material-ui/icons/Mail";

const FooterSection = () => {
  return (
    <>
      <div id="about" className="black-bg">
        <div className="row p-3">
          <div className="col-sm-12 col-md-6">
            <div className="d-flex justif-content-center align-item-center">
              <img src={eatachi_logo} alt="logo" className="img-about" />
              <div className="white">
                <h5 className="bold">About Eatachi</h5>
                <p>
                  Eatachi is easy to use Digitalization Platform for Restaurant.
                  Eatachi enable restaurant to have their own Online Ordering
                  System without any hassle or setup costs. Eatachi allows
                  restaurants to collect online orders and deliver by their
                  preferred medium without paying any unwanted commissions to
                  3rd party aggregators. Eatachi provides restaurants with
                  innovative digital solutions that enable restaurants to
                  increase sales & reduce costs.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 white">
            <div className="row">
              <div className="col-sm-12 col-md-6 contact_us">
                <h5 className="bold">Contact Us</h5>

                <p>
                  <CallIcon
                    className="icon-background"
                    style={{ marginRight: 10 }}
                  />
                  +92 21 32780110
                </p>
                <p>
                  <MailIcon
                    className="icon-background"
                    style={{ marginRight: 10 }}
                  />
                  support@eatachi.co
                </p>
              </div>
              <div className="col-sm-12 col-md-6 join_us">
                <h5 className="bold">Join Us & Stay Updated</h5>
                <ul>
                  <li className="icon">
                    <a
                      href="https://www.facebook.com/MyEatachi"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={facebook}
                        className="img-fluid"
                        alt="social-icon"
                      />
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      href="https://www.instagram.com/MyEatachi/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={Instagram}
                        className="img-fluid"
                        alt="social-icon"
                      />
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      href="https://www.linkedin.com/company/eatachi/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={linkedin}
                        className="img-fluid"
                        alt="social-icon"
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-12">
                <div className="footer">
                  <p className="text-right">
                    {" "}
                    &copy; Copyrights | Eatachi.com - All Rights Reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterSection;
